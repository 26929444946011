import React, { useState } from 'react';
import './sidenav.css';
import '../../assets/css/layout.css';
import { NavLink, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import { signout, isAuthenticated } from '../../Pages/helper/auth';
import CircularImage from '../CircularImage/CircularImage';
import ConfirmDialog from '../ConfirmDialog';
import { Avatar } from '@material-ui/core';
import { API } from '../../backendUrl';

const SideNav = ({ history }) => {
  const [sideNavItems, setSideNavItems] = useState([]);
  const { user } = isAuthenticated();
  const [confirm, setConfirm] = useState(false);

  const toggleConfirm = () => {
    setConfirm(!confirm);
  };

  useEffect(() => {
    let cookNavItems = [
      {
        id: 1,
        label: 'Overview',
        to: 'cook/dashboard',
        icon: 'fa-home',
      },
      {
        id: 2,
        label: 'My Meals',
        to: 'cook/mymeals',
        icon: 'fa-cutlery',
      },
      {
        id: 3,
        label: 'Messages',
        to: 'cook/messages',
        icon: 'fa-comment',
      },
      {
        id: 4,
        label: 'Add New Meal',
        to: 'cook/addFoodItem',
        icon: 'fa-plus-circle',
      },
      {
        id: 2,
        label: 'Profile',
        to: 'cook/profile',
        icon: 'fa-user',
      },
      {
        id: 6,
        label: 'Order',
        to: 'cook/order',
        icon: 'fa-shopping-cart',
      },
    ];
    let userNavItems = [
      {
        id: 1,
        label: 'Overview',
        to: 'user/dashboard',
        icon: 'fa-home',
      },
      {
        id: 2,
        label: 'Messages',
        to: 'user/messages',
        icon: 'fa-comment',
      },
      {
        id: 3,
        label: 'Profile',
        to: 'user/profile',
        icon: 'fa-user',
      },
      {
        id: 4,
        label: 'Favourites',
        to: 'user/favourites',
        icon: 'fa-heart',
      },
      {
        id: 5,
        label: 'Checkout',
        to: 'user/checkout',
        icon: 'fa-check',
      },
      {
        id: 6,
        label: 'Order',
        to: 'user/order',
        icon: 'fa-shopping-cart',
      },
    ];

    if (user) {
      if (user.role === 0) {
        setSideNavItems(userNavItems);
      } else if (user.role === 1) {
        setSideNavItems(cookNavItems);
      }
    }
  }, []);

  const SideNavItem = (props) => (
    <NavLink
      exact={true}
      className='side-nav-item padding-10-px'
      activeClassName='active'
      to={`/${props.to}`}
    >
      <i
        className={`fa ${props.icon} fa-lg margin-all-5px`}
        aria-hidden='true'
      ></i>
      {props.label}
    </NavLink>
  );

  const signoutUser = () => {
    signout(() => {
      history.push('/login');
    });
  };
  return (
    <div className='side-nav width-15'>
      <div className='width-100 d-flex align-center flex-column h-100vh'>
        <div className='logo'>
          <h1 className='text-bold'>Food Project</h1>
        </div>
        <div className='sidenav-user-details align-center d-flex flex-column'>
          <Avatar
            className='user-details'
            src={`${API}/uploads/${user.image}`}
          ></Avatar>
          <div>
            Welcome, <br />
            <span>{user.name}</span>
          </div>
        </div>

        <div className='side-nav-items width-100  flex-1 d-flex flex-column justify-space-between margin-all-20px'>
          <div className='d-flex flex-column width-100'>
            <div className='role-display'>
              {user.role === 0 ? 'User' : 'Cook'}
            </div>
            {sideNavItems.map((data) => (
              <SideNavItem label={data.label} to={data.to} icon={data.icon} />
            ))}
          </div>

          <div
            className='signout-user d-flex align-center padding-10-px'
            onClick={toggleConfirm}
          >
            <i
              className={`fa fa-power-off fa-lg margin-all-5px`}
              aria-hidden='true'
            ></i>
            <div className=''>Sign Out</div>
          </div>
        </div>
      </div>
      {confirm && (
        <ConfirmDialog
          open={confirm}
          description='Are you sure want to logout?'
          title='Exit From App??'
          handleClose={toggleConfirm}
          okAction={signoutUser}
        />
      )}
    </div>
  );
};

export default withRouter(SideNav);
