import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@material-ui/core';
import { isAuthenticated } from '../Pages/helper/auth';
import { changePassword } from '../Pages/helper/apihelper';
import { notify } from '../helper/NotificationHelper';

const ChangePasswordBox = () => {
  const [data, setData] = useState({
    current: '',
    newpass: '',
    cpass: '',
    loading: false,
  });

  const { current, newpass, cpass, loading } = data;

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };
  const submitForm = (event) => {
    event.preventDefault();

    console.log(data);

    if (!current) {
      notify({ type: 'danger', message: 'Must Enter Your current password!' });
      return;
    }
    if (!newpass || newpass.length < 6) {
      notify({
        type: 'danger',
        message: 'New Passwod must be 6 character long',
      });
      return;
    }
    if (cpass !== newpass) {
      notify({
        type: 'danger',
        message: 'Confirm Passwod and Password must be Same',
      });
      return;
    }

    const { user } = isAuthenticated();

    const passData = {
      userId: user._id,
      currentPassword: current,
      newPassword: newpass,
    };

    changePassword(passData).then((res) => {
      if (res) {
        if (res.message === 'Success') {
          setData({
            ...data,
            current: '',
            newpass: '',
            cpass: '',
            error: '',
            loading: false,
          });

          notify({
            type: 'success',
            message: 'Password Chnage Successfully',
          });
        } else {
          setData({
            ...data,
            loading: false,
          });
          notify({
            type: 'danger',
            message: 'Cannot change Password. Please provide correct data',
          });
        }
      } else {
        notify({
          type: 'danger',
          message: 'Somethiong went wrong',
        });

        setData({
          ...data,
          loading: false,
        });
      }
    });
  };

  return (
    <form autoComplete='off' noValidate>
      <Card>
        <CardHeader subheader='Change your password' title='Change Password' />
        <Divider />
        <form onSubmit={submitForm}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  helperText='Please enter your current password'
                  label='Current Password'
                  name='current'
                  value={current}
                  onChange={handleChange}
                  type='password'
                  required
                  variant='outlined'
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label='New Password'
                  name='newpass'
                  onChange={handleChange}
                  type='password'
                  value={newpass}
                  required
                  variant='outlined'
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label='Confirm Password'
                  name='cpass'
                  onChange={handleChange}
                  type='password'
                  value={cpass}
                  required
                  variant='outlined'
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box display='flex' justifyContent='flex-end' p={2}>
            <Button type='submit' color='primary' variant='contained'>
              Change Password
            </Button>
          </Box>
        </form>
      </Card>
    </form>
  );
};

ChangePasswordBox.propTypes = {
  className: PropTypes.string,
};

export default ChangePasswordBox;
