import React, { useState } from 'react';
import cook from '../assets/images/cok.png';
import { API } from '../backendUrl';
import axios from 'axios';
import UnAuthorized from '../hoc/UnAuthorized';
import { notify } from '../helper/NotificationHelper';
import { CircularProgress } from '@material-ui/core';

import redbg from '../assets/images/redbg.png';
import yellowbg from '../assets/images/yellowbg.png';

const Signup = ({ history }) => {
  const [data, setData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    role: '',
    loading: false,
    error: '',
    success: false,
  });

  const {
    email,
    password,
    confirmPassword,
    loading,
    error,
    role,
    success,
  } = data;

  const signUpSubmit = (event) => {
    event.preventDefault();
    if (isFormValid()) {
      console.log(data);
      const dataObj = {
        email: email,
        password: password,
        role: role,
      };
      console.log('SendData', dataObj);
      setData({
        ...data,
        loading: true,
      });
      axios
        .post(`${API}/signup`, dataObj)
        .then((data) => {
          setData({
            ...data,
            success: 'Signup success please verify your email',
            loading: false,
          });
          setTimeout(() => {
            console.log(data.data);
            history.push('/login');
          }, 2000);
        })
        .catch((err) => {
          if (err.response) {
            setData({
              ...data,
              error: err.response.data.error,
              loading: false,
            });

            setTimeout(
              () =>
                setData({
                  ...data,
                  error: false,
                }),
              3000
            );
          } else {
            setData({
              ...data,
              error: 'Server Not Working',
              loading: false,
            });

            setTimeout(
              () =>
                setData({
                  ...data,
                  error: false,
                }),
              3000
            );
          }
        });
    } else {
      setTimeout(
        () =>
          setData({
            ...data,
            error: false,
          }),
        3000
      );
    }
  };

  const onChangeHandler = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const showError = () => {
    notify({
      message: error,
      type: 'danger',
    });
  };
  const buildSuccess = () => {
    notify({
      message: 'Your Account created Sucessfully!. Check your Email to Verify',
      type: 'success',
    });
  };
  const buildLoading = () => <div className='show-loading'>Loading...</div>;

  const isFormValid = () => {
    if (email.trim() === '') {
      setData({
        ...data,
        error: 'Email cannot be Empty',
      });
      return false;
    }

    if (password.length < 6) {
      setData({
        ...data,
        error: 'Password must have length greater than 6',
      });
      return false;
    }
    if (password !== confirmPassword) {
      setData({
        ...data,
        error: 'Password and Confirm password must match',
      });
      return false;
    }

    if (role === '') {
      setData({
        ...data,
        error: 'Please Select Cook or Buyer',
      });
      return false;
    }
    return true;
  };

  const buildSignupForm = () => (
    <form class='login-form pt-3' onSubmit={signUpSubmit}>
      <div class='form-block-signup'>
        <span class='pr-3'>Register as</span>
        <div class='custom-control custom-radio custom-control-inline'>
          <input
            type='radio'
            name='role'
            value='0'
            className='custom-control-input'
            id='customRadioInline1'
            onChange={onChangeHandler}
          ></input>
          <label class='custom-control-label' for='customRadioInline1'>
            Customer{' '}
          </label>
        </div>
        <div class='custom-control custom-radio custom-control-inline'>
          <input
            type='radio'
            name='role'
            value='1'
            id='customRadioInline2'
            className='custom-control-input'
            onChange={onChangeHandler}
          ></input>
          <label class='custom-control-label' for='customRadioInline2'>
            Chef
          </label>
        </div>
      </div>
      <div class='form-block-signup'>
        <label>
          <i class='fa fa-envelope pr-2'></i>
          <span>Email</span>
        </label>
        <br />
        <input
          onChange={onChangeHandler}
          className='width-80'
          type='email'
          name='email'
          placeholder='abc@meetnepali.com'
          value={email}
          required={true}
        />
      </div>
      <div class='form-block-signup'>
        <label>
          <i class='fa fa-lock pr-2'></i>
          <span>Password</span>
        </label>
        <br />
        <input
          onChange={onChangeHandler}
          className='width-80'
          type='password'
          name='password'
          placeholder='Password '
          value={password}
          required={true}
        />
      </div>
      <div class='form-block-signup'>
        <label>
          <i class='fa fa-lock pr-2'></i>
          <span>Confirm Password</span>
        </label>
        <br />
        <input
          onChange={onChangeHandler}
          className='width-80'
          type='password'
          name='confirmPassword'
          placeholder='Password '
          value={confirmPassword}
          required={true}
        />
      </div>

      <div class='form-block-signup'>
        <div className='form-group margin-all-20px'>
          {loading ? (
            <CircularProgress color='primary' />
          ) : (
            <button
              type='submit'
              class='btn btn-success btn-sm text-white mt-2 px-4'
            >
              Register
            </button>
          )}
        </div>
      </div>
    </form>
  );
  return (
    <UnAuthorized>
      <section class='login-box position-relative'>
        <div class='top-right-bg'>
          <img src={yellowbg} alt='' />
        </div>
        <div class='top-left-bg'>
          <img src={redbg} alt='' />
        </div>
        <div class='bottom-left-bg'>
          <img src={yellowbg} alt='' />
        </div>
        <div class='bottom-right-bg'>
          <img src={redbg} alt='' />
        </div>
        {error && showError()}
        {success && buildSuccess()}
        {loading && buildLoading()}
        <div class='row'>
          <div class='col-md-6'>
            <h2 class='text-center pt-5'>Signup</h2>
            {buildSignupForm()}
          </div>
          <div class='col-md-6'>
            <div class='image-box'>
              <h3 class='text-center text-white pt-5'>
                {' '}
                Welcome to our community
              </h3>
              <div class='login-image pt-5'>
                <img src={cook} alt='' />
              </div>
            </div>
          </div>
        </div>
      </section>
    </UnAuthorized>
  );
};

export default Signup;
