import React from 'react';
import love from '../../assets/images/love.svg';
import './dishItem.css';
import ImageHelper from '../ImageHelper';
import { useState } from 'react';
import { useEffect } from 'react';
import Rating from '@material-ui/lab/Rating';
import { Link } from 'react-router-dom';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import YouTubeIcon from '@material-ui/icons/YouTube';
import VideoDialog from '../VideoDialog';

const CustumerDishItem = ({
  food,
  openDetail,
  addToCart,
  favourite,
  userId,
}) => {
  const [actice, setActice] = useState(false);
  const [openVideo, setOpenVideo] = useState('');
  const toggleVideoDialog = (e) => {
    e.stopPropagation();
    setOpenVideo(!openVideo);
  };

  useEffect(() => {}, []);
  const addClicked = (e) => {
    e.stopPropagation();
    addToCart(food);
  };
  const toogleFavourite = (e) => {
    e.stopPropagation();
    setActice(!actice);
    favourite(food);
  };
  return (
    <div className={`user-dish d-flex background-grey`} onClick={openDetail}>
      <ImageHelper
        product={food.image}
        className='radius-circle height-width-200px'
      />
      <div className='user-dish-details d-flex flex-column flex-1 justify-space-between'>
        <div className='dish-top width-100'>
          <div className='top-headings d-flex justify-space-between'>
            <div className='dish-heading'>{food.name}</div>
            <div className='dish-price'>
              Price: {food.price.currency.symbol} {food.price.amount}
            </div>
          </div>
          <div className='dish-description'>{food.description}</div>
        </div>

        <div className='dish-bottom d-flex justify-space-between align-bottom'>
          <div className='card-bottom-left'>
            <div className='quantity'>
              <span>Quantity :</span> {food.quantity.amount}{' '}
              {food.quantity.unit.name}
            </div>
            <div className='dish-cook'>
              <span>Cook :</span>{' '}
              {food.user && (
                <Tooltip title='View Profile'>
                  <Link to={`/profile/${food.user._id}`}>
                    {`${food.user.firstname} ${food.user.lastname}`}
                  </Link>
                </Tooltip>
              )}
            </div>
            {food.image && (
              <div className='dish-cook'>
                <span>Watch Video</span>{' '}
                <Tooltip title='View Profile'>
                  <IconButton
                    size='small'
                    style={{ color: 'red' }}
                    onClick={toggleVideoDialog}
                  >
                    <YouTubeIcon />
                  </IconButton>
                </Tooltip>
              </div>
            )}
            <div className='ratings d-flex align-center'>
              <Typography variant='h4'>{food.rating.toFixed(1)}</Typography>
              <Rating defaultValue={food.rating} precision={1} readOnly />
            </div>
          </div>
          <div className='card-bottom-right d-flex align-center'>
            {actice ? (
              <img
                src={love}
                alt=''
                className='card-bottom-img'
                onClick={toogleFavourite}
              />
            ) : (
              <i
                className='fa fa-heart-o fa-2x card-bottom-img'
                aria-hidden='true'
                onClick={toogleFavourite}
              />
            )}

            <i
              className='fa fa-cart-plus fa-2x card-bottom-img'
              aria-hidden='true'
              onClick={addClicked}
            />
          </div>
        </div>
      </div>
      {openVideo && (
        <VideoDialog
          open={openVideo}
          handleClose={toggleVideoDialog}
          videoLink={food.youtube}
        />
      )}
    </div>
  );
};

export default CustumerDishItem;
