import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Container, Tooltip } from '@material-ui/core';
import ProfileDetails from '../../Components/ProfileDetails';
import ConfirmDialog from '../../Components/ConfirmDialog';
import { signout } from '../helper/auth';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  })
);

const CompleteProfile = ({ history }) => {
  const classes = useStyles();
  const [confirm, setConfirm] = useState(false);
  const toggleConfirm = () => {
    setConfirm(!confirm);
  };

  const signoutUser = () => {
    signout(() => {
      history.push('/login');
    });
  };

  return (
    <div>
      <AppBar position='static'>
        <Toolbar>
          <Typography variant='h4' className={classes.title}>
            Food Project
          </Typography>
          <div>
            <Tooltip title='Logout'>
              <IconButton
                aria-haspopup='true'
                onClick={toggleConfirm}
                color='inherit'
              >
                <ExitToAppIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
      </AppBar>

      {confirm && (
        <ConfirmDialog
          open={confirm}
          description='Are you sure want to logout?'
          title='Exit From App??'
          handleClose={toggleConfirm}
          okAction={signoutUser}
        />
      )}

      <Container fixed>
        <ProfileDetails title='Complete a Profile to Continue' />
      </Container>
    </div>
  );
};

export default withRouter(CompleteProfile);
