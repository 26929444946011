import React, { useState } from 'react';
import './style.css';
import {
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { API } from '../../backendUrl';
import { Link } from 'react-router-dom';
import { Rating } from '@material-ui/lab';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import YouTubeIcon from '@material-ui/icons/YouTube';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import VideoDialog from '../VideoDialog';

const useStyles = makeStyles((theme) => ({
  image: {
    width: 200,
    height: 200,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

const DishDescription = ({ open, handleClose, food, addToCart }) => {
  const classes = useStyles();
  const [openVideo, setOpenVideo] = useState('');
  const toggleVideoDialog = (e) => {
    e.stopPropagation();
    setOpenVideo(!openVideo);
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll='paper'
        maxWidth='ls'
        fullWidth={true}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <DialogTitle id='scroll-dialog-title'>{food.name}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id='scroll-dialog-description' tabIndex={-1}>
            <Grid container spacing={3}>
              <Grid item sm={12} md={5} lg={4}>
                <Paper className={classes.paper}>
                  <Avatar
                    className={classes.image}
                    src={food.image ? `${API}/uploads/${food.image}` : ''}
                  />

                  <div className='ratings d-flex align-center'>
                    <Rating
                      defaultValue={food.rating}
                      precision={1}
                      className='margin-all-5px'
                      readOnly
                    />
                    <Typography variant='h4'>
                      {food.rating.toFixed(1)}
                    </Typography>
                  </div>

                  <div className='d-flex align-center'>
                    <Typography variant='inherit' className='margin-all-5px'>
                      Cook{' '}
                    </Typography>

                    <Tooltip title='View Profile' className='margin-all-5px'>
                      <Link to={`/profile/${food.user._id}`}>
                        <Typography variant='button'>
                          {food.user
                            ? ` ${food.user.firstname} ${food.user.lastname}`
                            : 'Unknown'}
                        </Typography>
                      </Link>
                    </Tooltip>
                  </div>

                  <Button
                    variant='contained'
                    color='primary'
                    size='small'
                    onClick={() => addToCart(food)}
                    className={classes.button}
                    startIcon={<ShoppingCartIcon />}
                  >
                    Add To cart
                  </Button>

                  {food.youtube && (
                    <div className='margin-all-5px'>
                      <Button
                        variant='contained'
                        style={{ color: 'white', backgroundColor: 'red' }}
                        size='small'
                        onClick={toggleVideoDialog}
                        className={classes.button}
                        startIcon={<YouTubeIcon />}
                      >
                        Watch Youtube Video
                      </Button>
                    </div>
                  )}
                </Paper>
              </Grid>
              <Grid item sm={12} md={7} lg={8}>
                <Grid container justify='center' direction='column'>
                  <div className='margin-all-5px'>
                    <Typography variant='h4'>{food.name}</Typography>
                  </div>
                  <div className='margin-all-5px'>
                    <Typography variant='h5'>
                      {food.price.currency.symbol} {food.price.amount}
                    </Typography>
                  </div>
                  <div className='margin-all-5px'>
                    <Typography variant='h5'>
                      Quantity :{food.quantity.amount} {food.quantity.unit.name}
                    </Typography>
                  </div>
                  <div className='margin-all-5px'>
                    <Typography variant='h5'>
                      Preperation Time :
                      {food.preperationTime
                        ? food.preperationTime
                        : 'Not Specified'}
                    </Typography>
                  </div>

                  <div className='margin-all-5px'>
                    <Typography variant='h5'>
                      Delivery Option :
                      {food.delivery ? (
                        food.delivery.map((f) => (
                          <Chip
                            label={f}
                            color='primary'
                            deleteIcon={<CheckCircleIcon />}
                          />
                        ))
                      ) : (
                        <Chip
                          label='No Delivery'
                          color='default'
                          deleteIcon={<CheckCircleIcon />}
                        />
                      )}
                    </Typography>
                  </div>

                  <div className='description margin-all-5px'>
                    <Typography variant='h4'>Description</Typography>
                    <Divider />
                    <Typography variant='body1'>{food.description}</Typography>
                  </div>

                  <div className='description'>
                    <Typography variant='h4'>Ingredients</Typography>
                    <Divider />
                    <Typography variant='body1'>{food.ingredients}</Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='secondary'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {openVideo && (
        <VideoDialog
          open={openVideo}
          handleClose={toggleVideoDialog}
          videoLink={food.youtube}
        />
      )}
    </div>
  );
};
export default DishDescription;

// <div className='dialog'>
//       <div className='backdrop' onClick={() => dismiss(food)}></div>
//       <div className='dialog-box background-grey'>
//         <div className='width-100 d-flex justify-end'>
//           <div className='close' onClick={() => dismiss(food)}>
//             Close
//           </div>
//         </div>
//         <div className='dialog-content d-flex'>
//           <div className='dialog-left width-30 d-flex flex-column justify-space-between align-center'>
//             <ImageHelper
//               product={food.image}
//               className='radius-circle dish-image'
//             />
//             <div className='cook-details margin-vertical-10'>
//               <div className='posted-by'>
//                 <span>Posted By :</span>{' '}
//                 {food.user
//                   ? `${food.user.firstname} ${food.user.lastname}`
//                   : 'Unknown'}
//               </div>
//               <button onClick={() => addToCart(food)}>Add To Cart</button>
//             </div>
//           </div>
//           <div className='dialog-right width-70 d-flex flex-column'>
//             <div className='dish-title'>{food.name}</div>
//             <div className='dish-price'>
//               {food.price.currency.symbol} {food.price.amount}
//             </div>
//             <div className='dish-combine'>
//               <div className='heading'>Description</div>
//               <hr />
//               <div className='description'>
//               {food.description}
//               </div>
//             </div>

//             <div className='dish-combine'>
//               <div className='heading'>Ingredients</div>
//               <hr />
//               <div className='description'>{food.ingredients}</div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
