import { API } from "../../backendUrl";
import axios from "axios";

//get all categories
export const getCategories = () => {
  return axios
    .get(`${API}/categories`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => console.log(err));
};
//get all categories
export const getAllCurrency = () => {
  return axios
    .get(`${API}/currencies`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => console.log(err));
};
//get all categories
export const getAllUnit = () => {
  return axios
    .get(`${API}/units`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => console.log(err));
};

//Products
export const createProduct = (userId, token, product) => {
  return fetch(`${API}/product/create/${userId}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: product,
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

//get All product
export const getProducts = () => {
  return fetch(`${API}/products`, {
    method: "GET",
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

//get a product
export const getProduct = (productId) => {
  return fetch(`${API}/product/${productId}`, {
    method: "GET",
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

//updateProduct

export const updateProduct = (productId, userId, token, product) => {
  return fetch(`${API}/product/${productId}/${userId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: product,
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

//delete Product
export const deleteProduct = (productId, userId, token) => {
  return fetch(`${API}/product/${productId}/${userId}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};
