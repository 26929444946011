import React from 'react';
import CircularImage from '../../Components/CircularImage/CircularImage';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Avatar } from '@material-ui/core';
import { API } from '../../backendUrl';

export default function ChatSideItem({ className, changeActive, user, order }) {
  dayjs.extend(relativeTime);
  return (
    <div
      className={`chat-item d-flex flex-wrap justify-center align-center padding-10-px ${className} margin-all-5px`}
      onClick={changeActive}
    >
      <Avatar
        src={`${API}/uploads/${user.image}`}
        alt={user.firstname && user.firstname}
      ></Avatar>
      <div className=' margin-horizontal-10 d-flex flex-column'>
        <div className='name text-bold'>
          {user.firstname
            ? `${user.firstname} ${user.lastname}`
            : 'Unknown Name'}
        </div>
        <div className='small-text'> #OrderId: {order.order}</div>
        <div className='timestamp'>{dayjs(order.createdAt).fromNow()}</div>
      </div>
    </div>
  );
}
