import React from 'react';
import NavBar from '../Components/NavBar';


const UnAuthorized = ({ className, children }) => {
  return (
    <div className={`main ${className}`}>
      <NavBar />
      {children}
    </div>
  );
};

export default UnAuthorized;
