import React from 'react';
import './assets/css/landing.css';
import './assets/css/layout.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Login from './Pages/Login';
import Signup from './Pages/Signup';
import CookDashboard from './Pages/Cook/CookDashboard';
import UserDashboard from './Pages/Custumer/UserDashboard';
import Checkout from './Pages/Custumer/Checkout';
import AddFood from './Pages/Cook/AddFood';
import MyMeals from './Pages/Cook/MyMeals';
import Message from './Pages/Both/Message';
import CookRoute from './PrivateRoutes/CookRoute';
import CustumerRoute from './PrivateRoutes/CustumerRoute';
import Order from './Pages/Both/Order';
import Favourite from './Pages/Custumer/Favourite';
import Profile from './Pages/Both/Profile';
import ProfileSetting from './Pages/Both/ProfileSetting';
import PrivateRoute from './PrivateRoutes/PrivateRoute';
import Test from './Pages/Both/Test';
import ChangePassword from './Pages/ChangePassword';
import CompleteProfile from './Pages/Both/CompleteProfile';

function App() {
  return (
    <Router>
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/login' exact component={Login} />
        <Route path='/signup' exact component={Signup} />
        <Route path='/forgetPassword/:token' exact component={ChangePassword} />

        <PrivateRoute path='/profile/:userId' exact component={Profile} />
        <PrivateRoute path='/test' exact component={Test} />
        <PrivateRoute
          path='/completeProfile'
          exact
          component={CompleteProfile}
        />

        <CookRoute path='/cook/dashboard' exact component={CookDashboard} />
        <CookRoute path='/cook/mymeals' exact component={MyMeals} />
        <CookRoute path='/cook/addFoodItem' exact component={AddFood} />
        <CookRoute path='/cook/messages' exact component={Message} />
        <CookRoute path='/cook/profile' exact component={Profile} />
        <CookRoute path='/cook/order' exact component={Order} />
        <CookRoute path='/cook/profileEdit' exact component={ProfileSetting} />

        <CustumerRoute path='/user/dashboard' exact component={UserDashboard} />
        <CustumerRoute path='/user/favourites' exact component={Favourite} />
        <CustumerRoute path='/user/profile' exact component={Profile} />
        <CustumerRoute path='/user/messages' exact component={Message} />
        <CustumerRoute path='/user/checkout' exact component={Checkout} />
        <CustumerRoute path='/user/order' exact component={Order} />
        <CustumerRoute
          path='/user/profileEdit'
          exact
          component={ProfileSetting}
        />
      </Switch>
    </Router>
  );
}

export default App;
