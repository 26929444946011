import React, { useState, useEffect } from 'react';
import Authorized from '../../hoc/Authorized';
import { getFoodItems, updateIsActive } from '../helper/apihelper';
import DishItem from '../../Components/DishItem/DishItem';
import { isAuthenticated } from '../helper/auth';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

const MyMeals = () => {
  // const [currentFood, setCurrentFood] = useState({});
  const [showDialog, setShowDialog] = useState(false);

  const [activeFoods, setActiveFoods] = useState([]);
  const [inactive, setInactive] = useState([]);

  const { user, token } = isAuthenticated();
  useEffect(() => {
    getFoodItems(user._id, token).then((data) => {
      findActiveAndInActive(data);
    });
  }, []);

  const findActiveAndInActive = (foods) => {
    let active = [];
    let inactive = [];
    foods.forEach((data) => {
      if (data.isActive) {
        if (!dayjs(data.isActive).isBefore()) {
          active.push(data);
        } else {
          inactive.push(data);
        }
      } else {
        inactive.push(data);
      }
    });

    setActiveFoods(active);
    setInactive(inactive);
  };

  const toogleClick = (food) => {
    setShowDialog(!showDialog);
    // setCurrentFood(food);
  };

  const emptyView = (message) => (
    <div className='width-100 height-100-px background-grey d-flex align-center justify-center border-radius-5px'>
      <div className='text'>{message}</div>
    </div>
  );

  const setActiveFood = (food, date) => {
    const data = {
      date: date,
    };
    updateIsActive(data, food._id, user._id, token).then((data) => {
      console.log(data);
    });

    food.isActive = date;
    setInactive(inactive.filter((data) => data._id !== food._id));
    activeFoods.push(food);
    setActiveFoods(activeFoods);
  };
  const setInActiveFood = (food, date) => {
    const data = {
      date: date,
    };
    updateIsActive(data, food._id, user._id, token).then((data) => {
      console.log(data);
    });
    food.isActive = '';
    setActiveFoods(activeFoods.filter((data) => data._id !== food._id));
    inactive.push(food);
    setInactive(inactive);
  };
  dayjs.extend(relativeTime);
  return (
    <>
      <Authorized>
        <div className='content padding-5-percent'>
          <div className='text'>Active Meals</div>
          <div className='dish-lists d-flex flex-wrap justify-space-between'>
            {activeFoods.length > 0
              ? activeFoods.map((food) => (
                  <DishItem
                    food={food}
                    toogleActive={toogleClick}
                    updateFood={setInActiveFood}
                    active={true}
                    className='background-secondary'
                  />
                ))
              : emptyView('No active meals found. Please add it to see here')}
          </div>

          <div className='text'>InActive Meals</div>
          <div className='dish-lists d-flex flex-wrap justify-space-between'>
            {inactive.length > 0
              ? inactive.map((food) => (
                  <DishItem
                    food={food}
                    toogleActive={toogleClick}
                    updateFood={setActiveFood}
                    active={false}
                  />
                ))
              : emptyView('No inactive meal add a meal to see here.')}
          </div>
        </div>
      </Authorized>
    </>
  );
};

export default MyMeals;
