import React from 'react';
import {
  makeStyles,
  Card,
  Box,
  Avatar,
  Divider,
  CardContent,
  Typography,
  CardActions,
  Button,
} from '@material-ui/core';
import { getAddress, uploadProfileImage } from '../Pages/helper/apihelper';

import { useEffect } from 'react';
import { useState } from 'react';
import { authenticate, isAuthenticated } from '../Pages/helper/auth';
import { notify } from '../helper/NotificationHelper';
import { API } from '../backendUrl';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 100,
    width: 100,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));

export default function ProfileCard() {
  const [profileImage, setProfileImage] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const classes = useStyles();
  const [address, setAddress] = useState('');
  const { user, token } = isAuthenticated();
  useEffect(() => {
    setImageUrl(user.image && `${API}/uploads/${user.image}`);
    getAddress().then((data) => {
      setAddress(
        `${data.name}, ${data.stateId.name}, ${data.stateId.countryId.name}`
      );
    });
  }, []);

  const uploadImage = () => {
    let bodyFormData = new FormData();

    bodyFormData.append('photo', profileImage);

    uploadProfileImage(user._id, token, bodyFormData).then((res) => {
      if (res) {
        console.log(res);
        setProfileImage('');
        setImageUrl(`${API}/uploads/${res.image}`);
        user.image = res.image;
        console.log(user);
        authenticate({ token, user }, () => {
          notify({
            message: 'Profile Image updated Sucessfully!',
            type: 'success',
          });
        });
      } else {
        notify({
          message:
            'Profile Image upload Failed. You can only upload png and jpg file',
          type: 'danger',
        });
      }
    });
  };

  const changePic = (data) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      setImageUrl(e.target.result);
    };
    reader.readAsDataURL(data);
  };

  const handleChange = (e) => {
    setProfileImage(e.target.files[0]);
    changePic(e.target.files[0]);
  };
  return (
    <Card>
      <CardContent>
        <Box alignItems='center' display='flex' flexDirection='column'>
          <label htmlFor='contained-button-file'>
            <Avatar className={classes.avatar} src={imageUrl} />
          </label>
          <Typography color='textPrimary' gutterBottom variant='h5'>
            {user.name}
          </Typography>
          <Typography color='textSecondary' variant='body1'>
            {address}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <input
          accept='image/*'
          className={classes.input}
          id='contained-button-file'
          multiple
          onChange={handleChange}
          type='file'
        />
        {profileImage ? (
          <Button fullWidth onClick={uploadImage}>
            Upload
          </Button>
        ) : (
          <label htmlFor='contained-button-file' style={{ width: '100%' }}>
            <Button
              variant='contained'
              fullWidth
              color='secondary'
              component='span'
            >
              Choose Image
            </Button>
          </label>
        )}
      </CardActions>
    </Card>
  );
}
