import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import {
  getStateFromCountry,
  getCityFromState,
  getCountries,
} from '../Pages/helper/apihelper';

export default function LocationDialog({ open, handleClose }) {
  const [countries, setCountries] = useState([]);

  const [states, setStates] = useState([]);

  const [cities, setCities] = useState([]);

  const [data, setData] = useState({
    country: '',
    state: '',
    city: '',
  });

  useEffect(() => {
    getCountries().then((res) => {
      if (res) {
        setCountries(res);
      }
    });
  }, []);

  const { country, state, city } = data;

  const getState = (e) => {
    setData({
      ...data,
      country: e.target.value,
    });

    const countryData = {
      countryId: e.target.value,
    };

    getStateFromCountry(countryData).then((data) => {
      setStates(data);
    });
  };

  const getCities = (e) => {
    setData({
      ...data,
      state: e.target.value,
    });

    const cityDta = {
      stateId: e.target.value,
    };

    getCityFromState(cityDta).then((data) => {
      setCities(data);
    });
  };

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle id='form-dialog-title'>
        <h2>Change Location</h2>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <FormControl variant='outlined' fullWidth>
              <InputLabel>Country</InputLabel>
              <Select
                name='country'
                value={country}
                fullWidth
                onChange={getState}
                label='Country'
              >
                {countries.map((option) => (
                  <MenuItem value={option._id}>{option.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item md={12} xs={12}>
            <FormControl variant='outlined' fullWidth>
              <InputLabel>State</InputLabel>
              <Select
                name='state'
                value={state}
                fullWidth
                onChange={getCities}
                label='State'
              >
                {states.map((option) => (
                  <MenuItem value={option._id}>{option.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item md={12} xs={12}>
            <FormControl variant='outlined' fullWidth>
              <InputLabel>City</InputLabel>
              <Select
                name='city'
                value={city}
                fullWidth
                onChange={handleChange}
                label='City'
              >
                {cities.map((option) => (
                  <MenuItem value={option._id}>{option.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Cancel
        </Button>
        <Button onClick={handleClose} color='primary'>
          Change Location
        </Button>
      </DialogActions>
    </Dialog>
  );
}
