import React, { useState } from 'react';
import Authorized from '../../hoc/Authorized';
import './style.css';
import { db, storage } from '../../config/firebase';
import firebase from 'firebase';
import { isAuthenticated } from '../helper/auth';
import ChatSideItem from '../../Components/ChatSideItem/ChatSideItem';
import ChatContainer from '../../Container/ChatContainer/ChatContainer';
import { useEffect } from 'react';
import { getChatUserList } from '../helper/apihelper';
import ChangeOrderStatusDialog from '../../Components/ChangeOrderStatusDilaog/ChangeOrderStatusDialog';

const Message = () => {
  const [userList, setUserList] = useState([]);
  const { user, token } = isAuthenticated();
  const [active, setActive] = useState('');
  const [showOrderDialog, setShowOrderDialog] = useState(false);
  useEffect(() => {
    getChatUserList(user._id, token).then((data) => {
      console.log(data);
      setUserList(data);
      changeActive(data[0]);
    });
  }, []);

  const changeActive = (activeUser) => {
    console.log(activeUser);
    setActive(activeUser);
  };

  const toggleShowDialog = () => {
    setShowOrderDialog(!showOrderDialog);
  };

  return (
    <>
      <Authorized>
        <div className='content d-flex'>
          {active && (
            <ChatContainer
              db={db}
              storage={storage}
              firebase={firebase}
              chatDetails={active}
              chatId={active._id}
              toggleShowDialog={toggleShowDialog}
            />
          )}
          <div className='side-cart d-flex flex-column align-center background-grey'>
            <div className='width-100 d-flex flex-column'>
              <div className='text text-center margin-all-20px'>Chats List</div>
              <div className='chat-lists'>
                {userList.length > 0 ? (
                  userList.map((u) => (
                    <ChatSideItem
                      className={u._id === active._id ? 'active' : ''}
                      changeActive={() => changeActive(u)}
                      user={user.role === 0 ? u.seller : u.buyer}
                      order={u}
                    />
                  ))
                ) : (
                  <div>No User in a List</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Authorized>
      {showOrderDialog && (
        <ChangeOrderStatusDialog
          close={toggleShowDialog}
          orderId={active.order}
        />
      )}
    </>
  );
};

export default Message;
