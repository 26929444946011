import React from 'react';
import logo from '../assets/images/logo.png';
import { Link, NavLink } from 'react-router-dom';

const navItems = [
  {
    label: 'Login',
    to: '/login',
    icon: 'fa fa-sign-in',
  },
  {
    label: 'SignUp',
    to: '/signup',
    icon: 'fa fa-edit',
  },
];

export default function NavBar() {
  return (
    <div class='containers'>
      <div class='l-nav'>
        <div class='logo'>
          <h2>
            <Link to='/'>Logo</Link>
          </h2>
        </div>
        <div class='accounts'>
          {navItems.map((item) => (
            <NavLink exact={true} activeClassName='' to={item.to}>
              <li className={item.icon}>{item.label}</li>
            </NavLink>
          ))}
        </div>
      </div>
    </div>
  );
}
