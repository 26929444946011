import React from "react";
import SideNav from "../Components/SideNav/SideNav";
import SideCart from "../Components/SideCart/SideCart";

const Authorized = ({ children }) => {
  return (
    <div className="main d-flex ">
      <SideNav />
      <div className="main-content flex-1">
        
        {children}
      </div>
      
    </div>
  );
};

export default Authorized;

// <div className="nav bg-color-white"></div>
