import React from "react";
import { API } from "../backendUrl";
import rocky from "../assets/images/rocky.jpeg";

const ImageHelper = ({ product, className }) => {

  const imageurl = product
    ? `${API}/uploads/${product}`
    : rocky;
  return <img src={imageurl} className={className} alt=""/>;
};

export default ImageHelper;
