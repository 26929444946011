import React, { useEffect } from 'react';
import Authorized from '../../hoc/Authorized';
import { isAuthenticated } from '../helper/auth';
import { useState } from 'react';
import { getCategories, getAllUnit } from '../helper/adminapicall';
import axios from 'axios';
import { API } from '../../backendUrl';
import { Redirect } from 'react-router-dom';
import { notify } from '../../helper/NotificationHelper';
import {
  TextField,
  Grid,
  FormControlLabel,
  Checkbox,
  InputLabel,
  FormControl,
  InputAdornment,
  OutlinedInput,
  FormLabel,
  FormGroup,
  Button,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ChipInput from 'material-ui-chip-input';

const AddFood = () => {
  const { user, token } = isAuthenticated();

  const [values, setValues] = useState({
    name: '',
    description: '',
    price: '',
    photo: '',
    category: '',
    unit: '',
    quantity: '',
    ingredients: '',
    tags: [],
    preparation_time: '',
    youtube: '',
    loading: false,
    error: '',
    yterror: '',
    createdProduct: '',
    getRedirect: false,
    formData: '',
  });

  const [units, setUnits] = useState([]);
  const [categories, setCategories] = useState([]);

  const [tags, setTags] = useState([]);
  const [delivery, setDelivery] = useState({
    free: false,
    pay: false,
    pickup: false,
  });

  const { free, pay, pickup } = delivery;

  const handleChangeDelivery = (event) => {
    setDelivery({ ...delivery, [event.target.name]: event.target.checked });
  };

  const {
    name,
    description,
    price,
    ingredients,
    category,
    quantity,
    unit,
    youtube,
    preparation_time,
    loading,
    error,
    yterror,
    getRedirect,
    formData,
  } = values;

  useEffect(() => {
    getCategories()
      .then((data) => {
        setCategories(data);
      })
      .catch();

    getAllUnit()
      .then((data) => {
        setUnits(data);
        console.log(data);
      })
      .catch();

    setValues({
      ...values,
      formData: new FormData(),
    });
  }, []);

  const handleAddChip = (chip) => {
    console.log(chip);
    tags.push(chip);
    setTags(tags);
  };
  const handleDeleteChip = (chip, index) => {
    tags.splice(index, 1);

    const newTags = tags.filter((c) => c === chip);
    // const newTags = tags;
    setTags(newTags);
  };

  const setTagsFormData = () => {
    formData.set('tags', tags);
  };
  const setDeliveryFormData = (deliveryOptions) => {
    formData.set('delivery', deliveryOptions);
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (youtube) {
      const rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
      if (!youtube.match(rx)) {
        setValues({
          ...values,
          yterror: 'Youtube video link is not valid!',
        });
        return;
      } else {
        setValues({
          ...values,
          yterror: '',
        });
      }
    }

    let deliveryOptions = [];
    if (free) {
      deliveryOptions.push('Free');
    }
    if (pay) {
      deliveryOptions.push('Pay');
    }
    if (pickup) {
      deliveryOptions.push('Pickup');
    }

    setDeliveryFormData(deliveryOptions);
    setTagsFormData();
    for (var pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }

    axios
      .post(`${API}/product/create/${user._id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        setValues({
          ...values,
          createdProduct: true,
        });
        successMessage();

        setTimeout(() => {
          setValues({
            ...values,
            getRedirect: true,
          });
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        errorMessage();
        setValues({
          ...values,
          error: true,
        });
      });
  };
  const successMessage = () =>
    notify({ message: 'Successfull adding meal!!', type: 'success' });

  const errorMessage = () =>
    notify({ message: 'Error While Adding Meal', type: 'danger' });

  const handleChange = (name) => (event) => {
    const value = name === 'photo' ? event.target.files[0] : event.target.value;
    formData.set(name, value);
    setValues({ ...values, [name]: value });
  };

  const addFoodForm = () => (
    <form onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            name='name'
            value={name}
            label='Meal Title'
            fullWidth
            onChange={handleChange('name')}
            variant='outlined'
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            label='Meal Description'
            multiline
            fullWidth
            rows={3}
            required
            onChange={handleChange('description')}
            name='description'
            value={description}
            variant='outlined'
          />
        </Grid>
        <Grid item md={5} sm={12} xs={12}>
          <FormControl fullWidth variant='outlined'>
            <InputLabel htmlFor='outlined-adornment-amount'>Price</InputLabel>
            <OutlinedInput
              id='outlined-adornment-amount'
              fullWidth
              type='number'
              value={price}
              onChange={handleChange('price')}
              startAdornment={
                <InputAdornment position='start'>
                  {user.currency ? user.currency : 'R.S.'}
                </InputAdornment>
              }
              labelWidth={60}
            />
          </FormControl>
        </Grid>

        <Grid item md={5} sm={8} xs={8}>
          <TextField
            required
            name='quantity'
            value={quantity}
            label='Quantity'
            type='number'
            onChange={handleChange('quantity')}
            fullWidth
            variant='outlined'
          />
        </Grid>
        <Grid item md={2} sm={4} xs={4}>
          <TextField
            select
            label='Quantity Unit'
            value={unit}
            fullWidth
            required
            InputLabelProps={{ shrink: true }}
            onChange={handleChange('unit')}
            SelectProps={{
              native: true,
            }}
            variant='outlined'
          >
            <option key={0} value=''>
              Select
            </option>

            {units.map((option) => (
              <option key={option._id} value={option._id}>
                {option.name}
              </option>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            select
            label='Select Meal Category'
            fullWidth
            required
            value={category}
            InputLabelProps={{ shrink: true }}
            onChange={handleChange('category')}
            SelectProps={{
              native: true,
            }}
            helperText='Select your currency'
            variant='outlined'
          >
            <option key={0} value=''>
              Select
            </option>

            {categories.map((option) => (
              <option key={option._id} value={option._id}>
                {option.name}
              </option>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            label='Meal Ingredients'
            multiline
            fullWidth
            rows={3}
            required
            onChange={handleChange('ingredients')}
            name='ingredients'
            value={ingredients}
            variant='outlined'
          />
        </Grid>

        <Grid item xs={12}>
          <ChipInput
            variant='outlined'
            value={tags}
            label='Tags'
            fullWidth
            placeholder='Enter the Tags'
            onAdd={(chip) => handleAddChip(chip)}
            onDelete={(chip, index) => handleDeleteChip(chip, index)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label='Preperation Time'
            fullWidth
            required
            onChange={handleChange('preparation_time')}
            name='preparation_time'
            value={preparation_time}
            variant='outlined'
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormControl component='fieldset'>
            <FormLabel component='legend'>Deliver Option</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    value={free}
                    onChange={handleChangeDelivery}
                    name='free'
                  />
                }
                label='Free Delivery'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={pay}
                    onChange={handleChangeDelivery}
                    name='pay'
                  />
                }
                label='Delivery With fee'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={pickup}
                    onChange={handleChangeDelivery}
                    name='pickup'
                  />
                }
                label='Pickup only'
              />
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            label='Youtube Video Link'
            fullWidth
            required
            onChange={handleChange('youtube')}
            name='youtube'
            value={youtube}
            variant='outlined'
            error={yterror}
            helperText={yterror && yterror}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <div className='form-group d-flex align-center'>
            <div className='label'>Upload Image</div>
            <input
              type='file'
              className='flex-1'
              onChange={handleChange('photo')}
            />
          </div>
        </Grid>

        <div className='d-flex'>
          <Button
            fullWidth
            type='submit'
            variant='contained'
            size='medium'
            color='secondary'
            startIcon={<CloudUploadIcon />}
          >
            Add Meal
          </Button>
        </div>
      </Grid>
    </form>
  );

  const performRedirect = () => {
    return <Redirect to='/cook/dashboard' />;
  };
  return (
    <Authorized>
      <div className='content padding-5-percent'>
        <h1>Add New Food</h1>
        {addFoodForm()}
        {getRedirect && performRedirect()}
      </div>
    </Authorized>
  );
};

export default AddFood;

// const addFoodForm = () => (
//   <form className="add-food-form d-flex flex-column">
//     <div className="form-group d-flex align-center">
//       <div className="label">Name</div>
//       <input
//         type="text"
//         className="flex-1"
//         onChange={handleChange('name')}
//         value={name}
//       />
//     </div>
//     <div className="form-group d-flex align-center">
//       <div className="label">Description</div>
//       <textarea onChange={handleChange('description')} className="flex-1">
//         {description}
//       </textarea>
//     </div>
//     <div className="form-group d-flex align-center">
//       <div className="label">Price</div>
//       <input
//         type="number"
//         className="flex-1"
//         onChange={handleChange('price')}
//         value={price}
//       />
//       <select onChange={handleChange('currency')} value={currency}>
//         {currencies &&
//           currencies.map((cate, index) => (
//             <option key={index} value={cate._id}>
//               {cate.symbol}
//             </option>
//           ))}
//       </select>
//     </div>
//     <div className="form-group d-flex align-center">
//       <div className="label">Ingridents</div>
//       <textarea
//         onChange={handleChange('ingredients')}
//         value={ingredients}
//         className="flex-1"
//       ></textarea>
//     </div>
//     <div className="form-group d-flex align-center">
//       <div className="label">Category</div>
//       <select
//         name="category"
//         onChange={handleChange('category')}
//         value={category}
//       >
//         {categories &&
//           categories.map((cate, index) => (
//             <option key={index} value={cate._id}>
//               {cate.name}
//             </option>
//           ))}
//       </select>
//     </div>

//     <div className="form-group d-flex align-center">
//       <div className="label">Quantity</div>
//       <input
//         type="number"
//         onChange={handleChange('quantity')}
//         className="flex-1"
//         value={quantity}
//       />
//       <select onChange={handleChange('unit')}>
//         {units &&
//           units.map((cate, index) => (
//             <option key={index} value={cate._id}>
//               {cate.name}
//             </option>
//           ))}
//       </select>
//     </div>

//     <div className="form-group d-flex align-center">
//       <div className="label">Tag List</div>
//       <input type="text" className="flex-1" onChange={handleChange('tags')} />
//     </div>

//     <div className="form-group d-flex">
//       <div className="label"></div>
//       <button className="align-center flex-1" onClick={onSubmit}>
//         Add Food
//       </button>
//     </div>
//   </form>
// );
