import { Avatar } from '@material-ui/core';
import React from 'react';
import { API } from '../../backendUrl';
import ImageHelper from '../ImageHelper';

const OrderTableItem = ({
  food,
}) => {
  return (
    <div className={`table-body d-flex `}>
      <div className='td flex-2'>
        <div className='d-flex align-center justify-space-around'>
          <div className='image flex-1 d-flex justify-center'>
            <Avatar
              aria-label='recipe'
              alt={food.food.name}
              src={food.food.image && `${API}/uploads/${food.food.image}`}
            />
          </div>
          <div className='flex-1'>
            <div className='name '>{food.food.name}</div>
          </div>
        </div>
      </div>
      <div className='td flex-1 text-center'>
        {food.food.price.currency.symbol}
        {food.food.price.amount}
      </div>
      <div className='td flex-1 text-center'>Quantity: {food.quantity}</div>
      {
        //   <div className="td flex-1 text-center">
        //   {food.status === 'Request' ? (
        //     user.role === 0 ? (
        //       <div className="padding-10-px background-secondary">
        //         Request Sent
        //       </div>
        //     ) : (
        //       <button
        //         onClick={() => changeOrderStatus(food.food, 'Active', order._id)}
        //       >
        //         Accept Request
        //       </button>
        //     )
        //   ) : (
        //     <button onClick={goToMessage}>Message</button>
        //   )}
        // </div>
      }
    </div>
  );
};

export default OrderTableItem;
