import React from 'react';
import {
  Paper,
  Grid,
  CardHeader,
  Card,
  Avatar,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { API } from '../backendUrl';
import { isAuthenticated } from '../Pages/helper/auth';

const useStyles = makeStyles({
  paper: {
    padding: '10px',
  },
  text: {
    textAlign: 'center',
    margin: '10px',
  },
});

export default function FirstMessage({ foods }) {
  const { user } = isAuthenticated();
  const classes = useStyles();
  return (
    <Paper elevation={2} variant='outlined' className={classes.paper}>
      <Typography variant='h3' component='h3' className={classes.text}>
        {user.role === 1
          ? 'You have received an order. '
          : 'You have posted an order. '}
        Start chating and complete the order
      </Typography>
      <Grid container spacing={2} justify='center' alignItems='center'>
        {foods.map((food) => (
          <Grid item xl={3} lg={3} xs={12} md={6} sm={12}>
            <Card>
              <CardHeader
                avatar={
                  <Avatar
                    aria-label='recipe'
                    alt={food.food.name}
                    src={`${API}/uploads/${food.food.image}`}
                  />
                }
                title={food.food.name}
                subheader={
                  <div>
                    <div>{`Quantity: ${food.quantity}`}</div>
                    <div>
                      Price:
                      {`${food.food.price.currency.symbol} ${food.food.price.amount}`}
                    </div>
                  </div>
                }
              />
            </Card>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
}
