import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  useMediaQuery,
  useTheme,
  Typography,
  TextField,
} from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import { isAuthenticated } from '../Pages/helper/auth';
import { useEffect } from 'react';
import { notify } from '../helper/NotificationHelper';

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfiedIcon />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfiedIcon />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon />,
    label: 'Satisfied',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon />,
    label: 'Very Satisfied',
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

export default function Review({
  open,
  handleClose,
  foods = [],
  order,
  userId,
  giveRating,
  rateFoods,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = isAuthenticated();
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState({
    review: '',
    rating: 0,
    order: order,
    writer: user._id,
    user: userId,
  });

  const { review, rating } = userData;
  console.log(foods);
  useEffect(() => {
    foods.forEach((food) => {
      data.push({
        rating: 0,
        review: '',
        order: order,
        writer: user._id,
        food: food.food._id,
      });
    });
    setData([...data]);
  }, []);
  const handleChange = (event, index) => {
    if (event.target.name.startsWith('rating')) {
      data[index].rating = event.target.value;
    } else if (event.target.name === 'review') {
      data[index].review = event.target.value;
    }
    setData([...data]);
  };
  const handleUserChange = (event) => {
    setUserData({
      ...userData,
      [event.target.name]: event.target.value,
    });
  };
  const submitReview = (e) => {
    e.preventDefault();

    let complateData = true;
    if (!userData.rating) {
      complateData = false;
      notify({
        type: 'danger',
        message: 'Please enter all the rating feild',
        title: 'Incomplete Data!',
      });
    }
    if (complateData && rateFoods) {
      data.forEach((f) => {
        if (!f.rating) {
          complateData = false;
          notify({
            type: 'danger',
            message: 'Please enter all the rating feild',
            title: 'Incomplete Data!',
          });
        }
      });
    }

    if (complateData) {
      //api call
      const finalData = [];
      rateFoods && (finalData = data);
      finalData.push(userData);
      giveRating(finalData);
      handleClose();
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby='responsive-dialog-title'
    >
      <form onSubmit={submitReview}>
        <DialogContent>
          <Typography component='legend'>
            {user.role === 1
              ? 'Leave a review for a buyer'
              : 'Leave a review for a cook.'}
          </Typography>
          <Rating
            name='rating'
            defaultValue={0}
            value={rating}
            required
            onChange={handleUserChange}
            getLabelText={(value) => customIcons[value].label}
            IconContainerComponent={IconContainer}
          />
          <TextField
            id='outlined-multiline-static'
            label='Give a feedback'
            fullWidth
            name='review'
            multiline
            required
            onChange={handleUserChange}
            rows={2}
            value={review}
            variant='outlined'
          />
        </DialogContent>
        {rateFoods &&
          data.map((d, i) => (
            <DialogContent>
              <Typography component='legend'>
                Review for {foods[i].food.name}
              </Typography>
              <Rating
                name={`rating${i}`}
                value={d.rating}
                required
                defaultValue={d.rating}
                onChange={(e) => handleChange(e, i)}
                getLabelText={(value) => customIcons[value].label}
                IconContainerComponent={IconContainer}
              />
              <TextField
                id='outlined-multiline-static'
                label='Give a feedback'
                fullWidth
                multiline
                name='review'
                rows={2}
                required
                value={d.review}
                onChange={(e) => handleChange(e, i)}
                variant='outlined'
              />
            </DialogContent>
          ))}

        <DialogActions>
          <Button autoFocus onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button type='submit' color='primary' autoFocus>
            Leave a Feedback
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
