import React from 'react';
import Authorized from '../../hoc/Authorized';
import cook from '../../assets/images/cook.jpg';
import food from '../../assets/images/food.jpg';
import img from '../../assets/images/rocky.jpeg';
import './style.css';
import { isAuthenticated } from '../helper/auth';
import { useState } from 'react';
import { useEffect } from 'react';
import {
  getUserDetails,
  getFoodItems,
  getUserRatings,
} from '../../Pages/helper/apihelper';
import { Rating } from '@material-ui/lab';
import EditIcon from '@material-ui/icons/Edit';
import { Avatar, Button } from '@material-ui/core';
import { API } from '../../backendUrl';
const Profile = ({ match }) => {
  const [user, setUser] = useState({});
  const [foods, setFoods] = useState([]);
  const [ratings, setRatings] = useState([]);

  useEffect(() => {
    const { user, token } = isAuthenticated();
    if (match.params.userId) {
      user._id = match.params.userId;
    }

    getUserDetails(user._id, token).then((res) => {
      if (res) {
        setUser(res);
        if (res.role === 1) {
          getFoodItems(res._id, token).then((data) => {
            setFoods(data);
          });
        }
      }
    });

    getUserRatings(user._id, token).then((res) => {
      console.log(res);
      if (res) {
        setRatings(res);
      }
    });
  }, []);

  return (
    <Authorized>
      <section className='profile-cover-image'>
        <img
          src={user.role === 1 ? cook : food}
          alt=''
          className='backgroud-img'
        />
        <div className='user-info d-flex flex-column align-center justify-center'>
          <Avatar
            style={{ width: '120px', height: '120px' }}
            alt={user.firstname && user.firstname}
            src={`${API}/uploads/${user.image}`}
          ></Avatar>

          <div className='d-flex align-center justify-center'>
            <div className='medium-text text-bold color-white'>
              {user.firstname
                ? `${user.firstname} ${user.lastname}`
                : 'Unknown Name'}
            </div>
          </div>
          {user._id === isAuthenticated().user._id && (
            <Button
              variant='contained'
              color='primary'
              size='small'
              href={user.role === 0 ? '/user/profileEdit' : '/cook/profileEdit'}
              startIcon={<EditIcon />}
            >
              Edit Profile
            </Button>
          )}
        </div>
      </section>

      <div className='user-profile-details d-flex justify-space-between flex-wrap'>
        <div className='section details-section flex-basis-3-col d-flex flex-column padding-5-px text-center'>
          <h2>User Details</h2>
          <div className='user-address'>
            <span>Address : </span>
            {user.location
              ? `${user.location.city.name},${user.location.state.name},${user.location.country.name}`
              : 'No Address Found'}
          </div>
          <div className='user-address'>
            <span>Short Info :</span>
            <div>{user.userinfo}</div>
          </div>
          <div>
            <span>Users Responsibility :</span>{' '}
            {user.role === 0 ? 'Buyer' : 'Cook'}
          </div>
        </div>
        {user.role === 1 && (
          <div className=' section foods-section flex-basis-3-col text-center'>
            <h2>Food posted</h2>
            <div className='food-items'>
              {foods.map((food) => (
                <div className='food-item d-flex align-center'>
                  <Avatar
                    aria-label='recipe'
                    className="margin-all-5px"
                    alt={food.name}
                    src={`${API}/uploads/${food.image}`}
                  />
                  <div className='food-name margin-all-5px'>{food.name}</div>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className='section review-section padding-5-px flex-basis-3-col text-justify text-center'>
          <h2>Review</h2>
          <div className='review-lists '>
            {ratings.length > 0 ? (
              ratings.map((rating) => (
                <div className='review-list d-flex justify-center align-center flex-column'>
                  <div className='d-flex align-center'>
                    {rating.writer.firstname} {rating.writer.lastname}
                    <span>
                      <Rating
                        name='half-rating'
                        defaultValue={rating.rating}
                        precision={1}
                        size='small'
                        readOnly
                      />
                    </span>
                  </div>

                  <div className='review-text'>{rating.review}</div>
                  <hr />
                </div>
              ))
            ) : (
              <div>No Ratings</div>
            )}
          </div>
        </div>
      </div>
    </Authorized>
  );
};

export default Profile;
