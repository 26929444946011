import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import YouTube from 'react-youtube';
import React, { useEffect, useState } from 'react';

const VideoDialog = ({ handleClose, open, videoLink }) => {
  const [videoId, setVideoId] = useState('');
  useEffect(() => {
    const rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
    const video = videoLink.match(rx);
    setVideoId(video[1]);
  }, []);

  const opts = {
    height: '350',
    width: '550',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    // event.target.pauseVideo();
  };
  return (
    <Dialog
      onClose={handleClose}
      fullWidth
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <DialogTitle id='customized-dialog-title' onClose={handleClose}>
        Video
      </DialogTitle>
      <DialogContent dividers>
        <YouTube videoId={videoId} opts={opts} onReady={_onReady} />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color='primary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default VideoDialog;
