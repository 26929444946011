import React from 'react';
import cookImg from '../assets/images/cok.png';
import chef from '../assets/images/chef.png';
import redbg from '../assets/images/redbg.png';
import yellowbg from '../assets/images/yellowbg.png';
import banner from '../assets/images/banner image.svg';
import UnAuthorized from '../hoc/UnAuthorized';

const chefSteps = [
  {
    id: 1,
    label:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores, eligendi.',
  },
  {
    id: 2,
    label:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores, eligendi.',
  },
  {
    id: 3,
    label:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores, eligendi.',
  },
  {
    id: 4,
    label:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores, eligendi.',
  },
];
const custumerSteps = [
  {
    id: 1,
    label:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores, eligendi.',
  },
  {
    id: 2,
    label:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores, eligendi.',
  },
  {
    id: 3,
    label:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores, eligendi.',
  },
  {
    id: 4,
    label:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores, eligendi.',
  },
];

const custumerFeedbacks = [
  {
    id: 1,
    label:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores, eligendi.',
    name: 'Lora Sans',
    image: cookImg,
  },
  {
    id: 2,
    label:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores, eligendi.',
    name: 'Lora Sans',
    image: cookImg,
  },
  {
    id: 3,
    label:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores, eligendi.',
    name: 'Lora Sans',
    image: cookImg,
  },
];

const chooseus = [
  {
    id: 1,
    label: 'Search nearby chefs',
    image: 'icon fa fa-search',
  },
  {
    id: 2,
    label: 'Fast services',
    image: 'icon fa fa-car',
  },
  {
    id: 3,
    label: 'Quality food',
    image: 'icon fa fa-cutlery',
  },
  {
    id: 4,
    label: 'Promote your Bussiness',
    image: 'icon fa fa-briefcase',
  },
];

export default function Home() {
  return (
    <UnAuthorized>
      <section class='banner'>
        <div class='containers'>
          <div class='row'>
            <div class='col-md-6 col-12'>
              <div class='banner-desc'>
                <h2>WELCOME TO</h2>
                <h1 style={{ color: 'var(--primary-color)' }}>CHOOSE FOOD</h1>
                <hr />
                <p class='text-center'>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eius
                  quidem eum provident maxime perspiciatis numquam minima
                  dignissimos asperiores fugit laborum voluptates nobis id,
                  maiores rem, inventore incidunt in! Quod, explicabo.
                </p>
                <button class='button btn-success py-2 px-5'>Join now</button>
              </div>
            </div>
            <div class='col-md-6 col-12'>
              <img src={banner} alt='banner' height='540px' width='100%' />
            </div>
          </div>
          <div class='rect1'></div>
        </div>
      </section>

      <section class='chooseus'>
        <div class='containers'>
          <h1>
            WHY <span>CHOOSE US ?</span>
          </h1>
          <div class='d-flex align-items-center justify-content-center flex-wrap'>
            {chooseus.map((c) => (
              <div class='feature '>
                <div class='f-icon'>
                  <div class={c.image}></div>
                </div>
                <div class='feature-text' style={{ 'text-align': 'center' }}>
                  {c.label}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section class='user-works mt-90 position-relative'>
        <div class='containers'>
          <div class='users-yellow-bg'>
            <img src={yellowbg} alt='' />
          </div>

          <h1 class='text-right pt-5'>KNOW HOW USER WORKS?</h1>
          <div class='row mt-4'>
            <div class='col-md-4'>
              <div class='user-image'>
                <img src={cookImg} alt='' />
              </div>
            </div>
            <div class='col-md-8 col-12 '>
              <div class='row '>
                {custumerSteps.map((step) => (
                  <div class='col-md-6 col-12'>
                    <div class='user-block d-flex align-items-center'>
                      <div class='circle'>
                        <div class='circle-icon'>{step.id}</div>
                      </div>
                      <div class='user-block-content'>
                        <p>{step.label}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div class='users-red-bg'>
            <img src={redbg} alt='' />
          </div>
        </div>
      </section>

      <section class='chef-works position-relative'>
        <div class='containers'>
          <div class='chef-yellow-bg'>
            <img src='images/yellow bg.png' alt='' />
          </div>

          <h1 class='text-left pt-5'>KNOW HOW CHEFS WORKS?</h1>
          <div class='row mt-4'>
            <div class='col-md-8 col-12 '>
              <div class='row '>
                {chefSteps.map((step) => (
                  <div class='col-md-6 col-12'>
                    <div class='chef-block d-flex align-items-center'>
                      <div class='circle-chef'>
                        <div class='circle-chef-icon'>{step.id}</div>
                      </div>
                      <div class='chef-block-content'>
                        <p>{step.label}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div class='col-md-4'>
              <div class='user-image'>
                <img src={chef} alt='' />
              </div>
            </div>
          </div>
          <div class='chef-red-bg'>
            <img src={redbg} alt='' />
          </div>
        </div>
      </section>

      <section class='client-review mt-90 '>
        <div class='containers position-relative'>
          <h1 class='text-center pt-5'>KNOW WHAT USER'S SAY !!</h1>
          <div class='row pt-5'>
            {custumerFeedbacks.map((c) => (
              <div class='col-md-4 col-12'>
                <div class='card ' style={{ border: 'none' }}>
                  <img class='card-img' src={chef} alt='Card image cap' />
                  <div class='card-body'>
                    <p class='card-text text-center'>{c.label}</p>
                    <p class='text-center'>
                      <b>-{c.name}</b>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div class='review-box'></div>
        </div>
      </section>
      <footer class='footer mt-90'>
        <div class='containers'>
          <div class='row'>
            <div class='col-md-4 col-12 pt-5 pl-5'>
              <h2 class='text-white'>LOGO</h2>
              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolor
                excepturi qui repellat illum modi praesentium a eveniet ab velit
                accusamus.Lorem ipsum dolor sit amet consectetur, adi pisicing
                elit. Dolor excepturi qui repellat illum modi praesentium{' '}
              </p>
            </div>
            <div class='col-md-4 col-12 pl-4 pt-5 pl-5'>
              <p>Any querirs, Let us know</p>
              <div class='message'>
                <form class='pt-4'>
                  <div class='form-group'>
                    <input
                      type='text'
                      class='form-control'
                      id='formGroupExampleInput'
                      placeholder='Email'
                    />
                  </div>
                  <div class='form-group'>
                    <input
                      type='text'
                      class='form-control'
                      id='formGroupExampleInput2'
                      placeholder='Message'
                    />
                  </div>
                  <button class='message-btn'>Submit</button>
                </form>
              </div>
            </div>
            <div class='col-md-4 col-12 pt-5 pl-5'>
              <p>Follow Us</p>
              <div class='footer-social-links d-flex'>
                <div class='footer-icon'>
                  <div class='icon fa fa-instagram'></div>
                </div>
                <div class='footer-icon'>
                  <div class='icon fa fa-facebook-f'></div>
                </div>
                <div class='footer-icon'>
                  <div class='icon fa fa-youtube'></div>
                </div>
                <div class='footer-icon'>
                  <div class='icon fa fa-twitter'></div>
                </div>
              </div>

              <div class='contact-us mt-5'>
                <p>
                  <span class='fa fa-map-marker'></span> Bafal, Kathmandu
                </p>
                <p>
                  <span class='fa fa-envelope'></span> Bafal, Kathmandu
                </p>
                <p>
                  <span class='fa fa-phone'></span> Bafal, Kathmandu
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr style={{ background: 'white' }} />
        <p class='text-center mb-0 pb-4'>
          &copy; Copyright 2020. All rights reserved
        </p>
      </footer>
    </UnAuthorized>
  );
}
