import React from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';

import Authorized from '../../hoc/Authorized';
import ProfileCard from '../../Components/ProfileCard';
import ProfileDetails from '../../Components/ProfileDetails';
import ChangePasswordBox from '../../Components/ChangePasswordBox';

const ProfileSetting = () => {
  return (
    <Authorized>
      <div className='content h-100-per background-grey padding-vertical-20px background'>
        <Container maxWidth='lg'>
          <Grid container spacing={3}>
            <Grid item lg={4} md={6} xs={12}>
              <ProfileCard />
            </Grid>
            <Grid item lg={8} md={6} xs={12}>
              <ProfileDetails />
              <div className='margin-all-20px'></div>
              <ChangePasswordBox />
            </Grid>
          </Grid>
        </Container>
      </div>
    </Authorized>
  );
};

export default ProfileSetting;
