import React from 'react';
import Authorized from '../../hoc/Authorized';
import { useEffect } from 'react';
import { deleteCartItem, getCartItem } from '../helper/apihelper';
import { isAuthenticated } from '../helper/auth';
import { useState } from 'react';

import { createOrder } from '../helper/apihelper';
import { Redirect } from 'react-router-dom';

import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Slide,
  DialogActions,
  IconButton,
} from '@material-ui/core';
import CartTableItem from '../../Components/CartTableItem';
import { notify } from '../../helper/NotificationHelper';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const Checkout = () => {
  const [cartItems, setCartItems] = useState([]);
  const [enableCheckout, setEnableCheckout] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const { user, token } = isAuthenticated();
  useEffect(() => {
    getCartItem(user._id, token).then((data) => {
      setCartItems(data);
      if (data.length > 0) {
        setEnableCheckout(true);
      }
    });
  }, []);

  const deleteFromCart = (cartId) => {
    console.log('hello');
    deleteCartItem(cartId, user._id, token).then((data) => {
      console.log('hello');
      const newCartItems = cartItems.filter((cart) => cart._id !== cartId);
      setCartItems(newCartItems);
      notify({
        message: 'Sucessfully removed food from cart!!',
        type: 'success',
      });
    });
  };

  const getRedirect = () => <Redirect to='/user/order' />;

  const orderFood = () => {
    const sellers = [];
    const orderData = [];
    cartItems.forEach((cart) => {
      if (sellers.includes(cart.food.user._id)) {
        orderData.forEach((d) => {
          if (d.seller === cart.food.user._id) {
            d.foods.push({ food: cart.food._id, quantity: cart.quantity });
          }
        });
      } else {
        sellers.push(cart.food.user._id);
        orderData.push({
          seller: cart.food.user._id,
          buyer: user._id,
          foods: [{ food: cart.food._id, quantity: cart.quantity }],
        });
      }
    });
    createOrder(user._id, token, orderData).then((data) => {
      setCartItems([]);
      setTimeout(() => {
        setRedirect(true);
      }, 500);
    });
    toggleDialog();
  };

  const toggleDialog = () => {
    setShowDialog(!showDialog);
  };
  const confirmDialog = () => (
    <Dialog
      open={showDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={toggleDialog}
      aria-labelledby='alert-dialog-slide-title'
      aria-describedby='alert-dialog-slide-description'
    >
      <DialogTitle id='alert-dialog-slide-title'>
        <h1>{'Are you sure want to checkout??'}</h1>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-slide-description'>
          <div>
            Currently we donot handle payments so you have to communicate with
            cook and arrange payments which is suitable for you both.
          </div>
          <div className='warning'>
            Note: **Please be carefull of frauds and donot give a personal
            details to anyone.
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleDialog} color='primary'>
          Cancel
        </Button>
        <Button onClick={orderFood} color='primary'>
          Order
        </Button>
      </DialogActions>
    </Dialog>
  );

  const getTotal = () => {
    let total = 0;
    cartItems.forEach((cart) => {
      total += cart.total;
    });
    return total;
  };
  return (
    <>
      <Authorized>
        <div className='content padding-all-50px'>
          <h1>Place Order</h1>

          <div className='cart-details '>
            <div className='text background-primary padding-10-px text-center'>
              Foods in your cart
            </div>
            <div className='table'>
              <div className='table-heading d-flex'>
                <div className='th flex-2'>Your item</div>
                <div className='th flex-1'>Quantity</div>
                <div className='th flex-1'>Price</div>
                <div className='th flex-1'>Total</div>
                <div className='th flex-1'>Actions</div>
              </div>

              {cartItems.length > 0 ? (
                cartItems.map((cart) => (
                  <CartTableItem cart={cart} deleteCartItem={deleteFromCart} />
                ))
              ) : (
                <div>No Item in Cart</div>
              )}

              <div className='table-heading d-flex justify-end'>
                <div className='margin-all-5px'>Grand Total: </div>
                <div className='margin-all-5px'>${getTotal()}</div>
              </div>
            </div>
            {enableCheckout && (
              <div className='d-flex margin-all-20px justify-end'>
                <button onClick={toggleDialog}>Checkout and Order Meal</button>
              </div>
            )}
          </div>
        </div>
      </Authorized>
      {confirmDialog()}
      {redirect && getRedirect()}
    </>
  );
};

export default Checkout;
