import React, { useState } from 'react';
import MessageCard from '../../Components/MessageCard/MessageCard';
import MessageImageCard from '../../Components/MessageImageCard/MessageImageCard';
import { useRef } from 'react';
import { useEffect } from 'react';
import { isAuthenticated } from '../../Pages/helper/auth';
import CircularImage from '../../Components/CircularImage/CircularImage';
import './style.css';
import FirstMessage from '../../Components/FirstMessage';
import { getOrderById } from '../../Pages/helper/apihelper';
import { notify } from '../../helper/NotificationHelper';
import { Avatar, Button, IconButton } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import SendIcon from '@material-ui/icons/Send';
import { API } from '../../backendUrl';
const ChatContainer = ({
  db,
  firebase,
  chatDetails,
  chatId,
  toggleShowDialog,
  storage,
}) => {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const { user, token } = isAuthenticated();
  const [imageFile, setImageFile] = useState('');
  const [displayImage, setDisplayImage] = useState('');
  const [foods, setFoods] = useState([]);

  useEffect(() => {
    getOrderById(user._id, chatDetails.order, token).then((res) => {
      if (res) {
        console.log(res);
        setFoods(res.foods);
      } else {
        notify({
          message: 'Something Went Wrong while fetching Foods',
          title: 'Oops!',
          type: 'danger',
        });
      }
    });
  }, [chatId]);

  useEffect(() => {
    const ref = db
      .collection('chats')
      .doc(chatId)
      .collection('messages')
      .orderBy('date', 'asc');
    ref.onSnapshot((data) => {
      setMessages(data.docs.map((d) => d.data()));
    });
  }, [chatId]);

  const sendMessage = (e) => {
    e.preventDefault();
    if (input.trim() !== '') {
      const message = {
        message: input,
        type: 'text',
        date: firebase.firestore.FieldValue.serverTimestamp(),
        userId: user._id + '',
      };
      addMessageToDatabase(message);
      setInput('');
    }
    if (imageFile !== '') {
      handleFireBaseUpload();
    }
  };

  const addMessageToDatabase = (message) => {
    db.collection('chats')
      .doc(chatId + '')
      .collection('messages')
      .add(message);
  };

  const handleImageChange = (e) => {
    const image = e.target.files[0];
    setImageFile(image);
    changePic(e.target.files[0]);
  };

  const handleFireBaseUpload = () => {
    setDisplayImage('');
    console.log(imageFile);
    const uploadTask = storage.ref(`/images/${imageFile.name}`).put(imageFile);
    uploadTask.on(
      'state_changed',
      (snapShot) => {
        console.log(snapShot);
      },
      (err) => {
        console.log(err);
      },
      () => {
        storage
          .ref('images')
          .child(imageFile.name)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            console.log('Success');
            const message = {
              message: fireBaseUrl,
              type: 'image',
              date: firebase.firestore.FieldValue.serverTimestamp(),
              userId: user._id + '',
            };
            addMessageToDatabase(message);
          });
      }
    );
  };

  const messagesEndRef = useRef(null);
  const loadMessage = (message) => {
    if (message.type === 'first_message') {
      return <FirstMessage foods={foods} />;
    } else if (message.type === 'text') {
      return <MessageCard message={message} userId={user._id} />;
    } else {
      return <MessageImageCard message={message} userId={user._id} />;
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const changePic = (data) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      setDisplayImage(e.target.result);
    };
    reader.readAsDataURL(data);
  };

  useEffect(scrollToBottom, [messages]);
  return (
    <div className='chat-section flex-1 d-flex flex-column h-100vh'>
      <div className='chat-nav background-secondary padding-10-px text d-flex'>
        <Avatar
          src={
            user.role === 0
              ? `${API}/uploads/${chatDetails.seller.image}`
              : `${API}/uploads/${chatDetails.buyer.image}`
          }
        />
        {user.role == 0
          ? `${chatDetails.seller.firstname} ${chatDetails.seller.lastname}`
          : `${chatDetails.buyer.firstname} ${chatDetails.buyer.lastname}`}
      </div>
      <div className='message-section padding-10-px'>
        {messages.length > 0 ? (
          messages.map((message) => loadMessage(message))
        ) : (
          <div>You haven't message each other</div>
        )}
        <div ref={messagesEndRef} />
      </div>

      <form
        id='message-form'
        onSubmit={sendMessage}
        className='input-section d-flex flex-column margin-all-20px'
      >
        <input
          type='text'
          className='flex-1'
          value={input}
          placeholder='Type a Message'
          onChange={(e) => setInput(e.target.value)}
        />
        <div className='message-actions d-flex justify-space-between'>
          <div className='action-buttons d-flex align-center'>
            <input
              type='file'
              name='image'
              id='image'
              style={{ display: 'none' }}
              onChange={handleImageChange}
            />
            <label htmlFor='image'>
              <IconButton
                variant='contained'
                fullWidth
                size='small'
                color='secondary'
                component='span'
              >
                <ImageIcon />
              </IconButton>
            </label>

            <img
              src={displayImage}
              style={{ display: displayImage ? '' : 'none' }}
            />
            {
              //   <button
              //   type='button'
              //   className='h-100-per'
              //   onClick={toggleShowDialog}
              // >
              //   Change Order Status
              // </button>
            }
          </div>

          <Button
            type='submit'
            variant='contained'
            color='secondary'
            size='small'
            endIcon={<SendIcon />}
          >
            Send
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ChatContainer;
