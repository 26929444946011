import React from 'react';
import Authorized from '../../hoc/Authorized';
import DishItem from '../../Components/DishItem/DishItem';
import { useState, useEffect } from 'react';
import { getFoodItems } from '../helper/apihelper';
import { isAuthenticated } from '../helper/auth';
import { Container, Grid } from '@material-ui/core';
import DashboardCard from '../../Components/DashboardCard';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import MoneyIcon from '@material-ui/icons/Money';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import { colors } from '@material-ui/core';

const CookDashboard = () => {
  const [foods, setFoods] = useState([]);

  const dashboardItems = [
    {
      label: 'Total Meals',
      value: 5,
      icon: <FastfoodIcon />,
      color: colors.blue[600],
    },
    {
      label: 'Active Meals',
      value: 3,
      icon: <FastfoodIcon />,
      color: colors.blueGrey[600],
    },
    {
      label: 'Total Earned',
      value: '$100',
      icon: <AttachMoneyIcon />,
      color: colors.red[600],
    },
    {
      label: 'Total Order',
      value: 100,
      icon: <ShoppingBasketIcon />,
      color: colors.green[600],
    },
  ];

  useEffect(() => {
    const { user, token } = isAuthenticated();
    getFoodItems(user._id, token).then((data) => {
      setFoods(data);
    });
  }, []);

  return (
    <Authorized>
      <div className='content padding-5-percent'>
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            {dashboardItems.map((item) => (
              <Grid item lg={3} sm={6} xl={3} xs={12}>
                <DashboardCard item={item} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </div>
    </Authorized>
  );
};

export default CookDashboard;
