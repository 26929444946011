import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { isAuthenticated, authenticate } from '../Pages/helper/auth';
import {
  getUserInfo,
  getAddress,
  updateUserInfo,
  getCountries,
  getStateFromCountry,
  getCityFromState,
} from '../Pages/helper/apihelper';
import { notify } from '../helper/NotificationHelper';
import { getAllCurrency } from '../Pages/helper/adminapicall';
import { useHistory } from 'react-router-dom';

const ProfileDetails = ({ title = 'Edit a Profile' }) => {
  const history = useHistory();
  const [countries, setCountries] = useState([]);

  const [states, setStates] = useState([]);

  const [cities, setCities] = useState([]);

  const [currencies, setCurrencies] = useState([]);

  const [data, setData] = useState({
    email: '',
    firstname: '',
    lastname: '',
    userinfo: '',
    country: '',
    state: '',
    city: '',
    currency: '',
    updated: '',
    error: '',
    loading: false,
  });

  const {
    firstname,
    lastname,
    userinfo,
    email,
    country,
    state,
    city,
    currency,
    error,
    updated,
    loading,
  } = data;

  useEffect(() => {
    const { user, token } = isAuthenticated();

    getAddress();

    getUserInfo(user._id, token).then((res) => {
      console.log('Details ', res);
      setData({
        ...data,
        email: res.email,
        firstname: res.firstname,
        lastname: res.lastname,
        userinfo: res.userinfo,
        currency: res.currency ? res.currency : '',

        country: res.location ? res.location.country._id : '',
        state: res.location ? res.location.state._id : '',
        city: res.location ? res.location.city._id : '',
      });
      res.location && setCities([res.location.city]);
      res.location && setStates([res.location.state]);
    });

    getCountries().then((res) => {
      setCountries(res);
    });

    getAllCurrency()
      .then((data) => {
        setCurrencies(data);
        console.log(data);
      })
      .catch();
  }, []);

  const getState = (e) => {
    setData({
      ...data,
      country: e.target.value,
    });

    const countryData = {
      countryId: e.target.value,
    };

    getStateFromCountry(countryData).then((data) => {
      setStates(data);
    });
  };

  const getCities = (e) => {
    setData({
      ...data,
      state: e.target.value,
    });

    const cityDta = {
      stateId: e.target.value,
    };

    getCityFromState(cityDta).then((data) => {
      setCities(data);
    });
  };

  const updateProfile = (e) => {
    e.preventDefault();

    console.log(data);
    if (!firstname || !lastname || !country || !state || !city || !currency) {
      notify({
        type: 'danger',
        message: 'Please Enter All Data to Continue',
        title: 'Incomplete Data',
      });
      return;
    }

    const updateData = {
      firstname: firstname,
      lastname: lastname,
      userinfo: userinfo,
      location: {
        country: country,
        state: state,
        city: city,
      },
      currency: currency,
    };
    setData({
      ...data,
      loading: true,
    });
    const { user, token } = isAuthenticated();
    updateUserInfo(user._id, token, updateData)
      .then((res) => {
        if (res) {
          console.log(res);
          setData({
            ...data,
            loading: false,
            updated: true,
          });
          const newCurrency = getSymbolFromCurrency();
          user.name = res.firstname && `${res.firstname} ${res.lastname}`;
          user.currency = newCurrency;
          user.profileCompleted = res.profileCompleted
            ? res.profileCompleted
            : false;
          authenticate({ token, user }, () => {
            notify({
              title: 'Great!',
              message: 'Profile Updated Sucessfully',
              type: 'success',
            });
          });
          history.push(user.role === 0 ? '/user/profile' : '/cook/profile');
        } else {
          notify({
            title: 'Oops!',
            message: 'Profile Updated Failed!',
            type: 'danger',
          });
        }
      })
      .catch((err) => {
        setData({
          ...data,
          loading: false,
          error: 'Error Updating Profile',
        });

        notify({
          title: 'Oops!',
          message: 'Cannot update profile',
          type: 'danger',
        });
      });
  };

  const getSymbolFromCurrency = () => {
    let symbol = '';
    currencies.forEach((c) => {
      if (c._id === currency) {
        symbol = c.symbol;
      }
    });
    return symbol;
  };

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <form autoComplete='off'>
      <Card>
        <CardHeader subheader='Edit a profile' title={title} />
        <Divider />
        <form onSubmit={updateProfile}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  helperText='Please specify the first name'
                  label='First name'
                  name='firstname'
                  onChange={handleChange}
                  required
                  value={firstname}
                  variant='outlined'
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label='Last name'
                  name='lastname'
                  onChange={handleChange}
                  required
                  value={lastname}
                  variant='outlined'
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label='Email Address'
                  name='email'
                  onChange={handleChange}
                  required
                  value={email}
                  variant='outlined'
                  disabled
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label='Phone Number'
                  name='phone'
                  onChange={handleChange}
                  type='number'
                  variant='outlined'
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label='Short Introduction Bio'
                  name='userinfo'
                  value={userinfo}
                  onChange={handleChange}
                  type='text'
                  required
                  variant='outlined'
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel>Country</InputLabel>
                  <Select
                    name='country'
                    value={country}
                    fullWidth
                    required
                    onChange={getState}
                    label='Country'
                  >
                    {countries.map((option) => (
                      <MenuItem value={option._id}>{option.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={6} xs={12}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel>State</InputLabel>
                  <Select
                    name='state'
                    value={state}
                    fullWidth
                    required
                    onChange={getCities}
                    label='State'
                  >
                    {states.map((option) => (
                      <MenuItem value={option._id}>{option.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={6} xs={12}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel>City</InputLabel>
                  <Select
                    name='city'
                    value={city}
                    fullWidth
                    required
                    onChange={handleChange}
                    label='City'
                  >
                    {cities.map((option) => (
                      <MenuItem value={option._id}>{option.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={6} xs={12}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel>Choose Currency</InputLabel>
                  <Select
                    name='currency'
                    value={city}
                    fullWidth
                    value={currency}
                    onChange={handleChange}
                    label='Currency'
                  >
                    {currencies.map((option) => (
                      <MenuItem value={option._id}>{option.symbol}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box display='flex' justifyContent='flex-end' p={2}>
            <Button type='submit' color='primary' variant='contained'>
              Save details
            </Button>
          </Box>
        </form>
      </Card>
    </form>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string,
};

export default ProfileDetails;
