import React from 'react';
import UnAuthorized from '../hoc/UnAuthorized';
import { useState } from 'react';
import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  Grid,
  TextField,
  Box,
  Button,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { changePasswordSalt } from './helper/apihelper';
import { useHistory } from 'react-router-dom';
import { notify } from '../helper/NotificationHelper';

export default function ChangePassword() {
  const [data, setData] = useState({
    newpass: '',
    cpass: '',
  });

  const history = useHistory();

  const { token } = useParams();

  const [error, setError] = useState('');

  const { newpass, cpass } = data;

  const submitChangePassword = (e) => {
    e.preventDefault();
    if (newpass === '' || newpass.length < 6) {
      setError('Password must be longer than 6 character');
      return;
    }

    if (newpass !== cpass) {
      setError('Password and confirm password not match');
      return;
    } else {
      setError('');
    }

    const passwordData = {
      password: newpass,
      token: token,
    };
    changePasswordSalt(passwordData).then((res) => {
      if (res) {
        notify({
          title: 'Oops!',
          message: 'Sucessfullty Changed Password!',
          type: 'success',
        });
        history.push('/login');
      } else {
        notify({
          title: 'Oops!',
          message: 'Error While Changing Password.Try Again',
          type: 'danger',
        });
      }
    });

  };

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <UnAuthorized>
      <Card>
        <CardHeader subheader="Change your password" title="Change Password" />
        <Divider />
        <form onSubmit={submitChangePassword}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="New Password"
                  name="newpass"
                  onChange={handleChange}
                  type="password"
                  value={newpass}
                  helperText={error ? error : ''}
                  error={error}
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Confirm Password"
                  name="cpass"
                  onChange={handleChange}
                  type="password"
                  value={cpass}
                  error={error}
                  helperText={error ? error : ''}
                  required
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button type="submit" color="primary" variant="contained">
              Change Password
            </Button>
          </Box>
        </form>
      </Card>
    </UnAuthorized>
  );
}
