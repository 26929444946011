import React, { useEffect } from 'react';
import Authorized from '../../hoc/Authorized';
import { useState } from 'react';
import { isAuthenticated } from '../helper/auth';
import CustumerDishItem from '../../Components/CustumerDishItem/CustumerDishItem';
import {
  getFavouriteFoods,
  addFoodToCart,
  addFavourite,
  removeFavourite,
} from '../../Pages/helper/apihelper';
import { notify } from '../../helper/NotificationHelper';

const Favourite = () => {
  const [favFoods, setFavFoods] = useState([]);
  const { user, token } = isAuthenticated();

  useEffect(() => {
    getFavouriteFoods(user._id, token).then((res) => {
      console.log(res);
      setFavFoods(res);
    });
  }, []);

  const addToCart = (food) => {
    const data = {
      food: food._id,
      user: user._id,
      price: food.price.amount,
      total: food.price.amount,
    };
    addFoodToCart(data, food._id, user._id, token)
      .then((data) => {
        if (data.message) {
          notify({
            title: 'Sorry!',
            message: 'Already in your Cart!!',
            type: 'danger',
          });
        } else {
          notify({ message: 'Food Added To Cart!!' });
        }
      })
      .catch((err) => {});
  };

  const addRemoveFavourite = (food) => {
    let added = false;

    if (added) {
      console.log('Remove favourite');
      removeFavourite(food._id, user._id, token).then((data) => {
        console.log(data);
      });
    } else {
      addFavourite(food._id, user._id, token).then((data) => {
        console.log(data);
      });
    }
  };

  return (
    <Authorized>
      <div className="content d-flex">
        <div className="dish-items flex-1 padding-5-percent">
          {favFoods.map((food) => (
            <CustumerDishItem
              key={food.foodId._id}
              food={food.foodId}
              addToCart={addToCart}
              favourite={addRemoveFavourite}
              userId={user._id}
            />
          ))}
        </div>
      </div>
    </Authorized>
  );
};

export default Favourite;
