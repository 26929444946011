import React from 'react';
import Authorized from '../../hoc/Authorized';
import axios from 'axios';
import { API } from '../../backendUrl';
import { useState } from 'react';
import { useEffect } from 'react';
import CustumerDishItem from '../../Components/CustumerDishItem/CustumerDishItem';
import SearchIcon from '@material-ui/icons/Search';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import DishDescription from '../../Components/DishDescription/DishDescription';
import SideCart from '../../Components/SideCart/SideCart';
import { isAuthenticated } from '../helper/auth';
import {
  addFoodToCart,
  addFavourite,
  removeFavourite,
  searchFoods,
  getAddress,
} from '../helper/apihelper';
import { notify } from '../../helper/NotificationHelper';
import { Pagination } from '@material-ui/lab';
import { IconButton, Button } from '@material-ui/core';
import LocationDialog from '../../Components/LocationDialog';
import AppBarComponent from '../../Components/AppBarComponent';

const UserDashboard = () => {
  const [foods, setFoods] = useState([]);
  const [currentFood, setCurrentFood] = useState({});

  const [showDialog, setShowDialog] = useState(false);
  const [search, setSearch] = useState('');
  const { user, token } = isAuthenticated();
  const [reload, setReload] = useState(false);

  const [limit, setLimit] = useState(process.env.REACT_APP_PAGINATION_LIMIT);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [address, setAddress] = useState('');
  const [locationOpen, setLocationOpen] = useState(false);

  const toggleLocationOpen = () => {
    setLocationOpen(!locationOpen);
  };

  useEffect(() => {
    console.log(limit);
    getFoodItems();
    getAddress().then((data) => {
      setAddress(
        `${data.name}, ${data.stateId.name}, ${data.stateId.countryId.name}`
      );
    });
  }, [page]);

  const getFoodItems = () => {
    axios.get(`${API}/products?limit=${limit}&page=${page}`).then((data) => {
      setFoods(data.data.products);
      setTotalPage(data.data.totalPage);
    });
  };

  const click = (food) => {
    setShowDialog(!showDialog);
    setCurrentFood(food);
  };

  const dishDescriptionDialog = () => (
    <DishDescription
      open={showDialog}
      food={currentFood}
      handleClose={click}
      addToCart={addToCart}
    />
  );

  const addToCart = (food) => {
    const data = {
      food: food._id,
      user: user._id,
      price: food.price.amount,
      total: food.price.amount,
    };
    addFoodToCart(data, food._id, user._id, token)
      .then((data) => {
        if (data.message) {
          notify({
            title: 'Sorry!',
            message: 'Already in your Cart!!',
            type: 'danger',
          });
        } else {
          setReload(!reload);
          notify({ message: 'Food Added To Cart!!' });
        }
      })
      .catch((err) => {});
  };
  const searchFood = (keyword) => {
    if (keyword !== '') {
      searchFoods(keyword, limit, page).then((data) => {
        setFoods(data.products);
        setTotalPage(data.totalPage);
      });
    }
  };

  const addRemoveFavourite = (food) => {
    let added = false;

    if (added) {
      console.log('Remove favourite');
      removeFavourite(food._id, user._id, token).then((data) => {
        console.log(data);
      });
    } else {
      addFavourite(food._id, user._id, token).then((data) => {
        console.log(data);
      });
    }
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <Authorized>
        <div className='content d-flex flex-column'>
          <AppBarComponent reload={reload} />
          <div className='d-flex'>
            <div className='dish-items flex-1 padding-5-percent'>
              <div className='search-nav padding-10-px d-flex align-center'>
                <input
                  type='text'
                  placeholder={`Searching in ${address}`}
                  onChange={(e) => setSearch(e.target.value)}
                  className='width-60'
                />
                <IconButton onClick={() => searchFood(search)}>
                  <SearchIcon />
                </IconButton>
                <IconButton onClick={toggleLocationOpen} color='secondary'>
                  <EditLocationIcon />
                </IconButton>
                {
                  //   <Button
                  //   variant='contained'
                  //   color='primary'
                  //   size='small'
                  //   startIcon={<SearchIcon />}
                  // >
                  //   Advance Search
                  // </Button>
                }
              </div>
              {foods.map((food) => (
                <CustumerDishItem
                  key={food._id}
                  food={food}
                  openDetail={() => click(food)}
                  addToCart={addToCart}
                  favourite={addRemoveFavourite}
                  userId={user._id}
                />
              ))}
              <div className='d-flex justify-center align-center '>
                {totalPage > 0 && (
                  <Pagination
                    count={totalPage}
                    className='background-grey'
                    page={page}
                    color='secondary'
                    variant='outlined'
                    onChange={handleChange}
                  />
                )}
              </div>
            </div>
            {
              // <SideCart reload={reload} />
            }
          </div>
        </div>
      </Authorized>
      {showDialog && dishDescriptionDialog()}
      <LocationDialog open={locationOpen} handleClose={toggleLocationOpen} />
    </>
  );
};

export default UserDashboard;
