import { store } from 'react-notifications-component';

export const notify = ({
  title = 'Wonderful!',
  message = 'Success',
  type = 'default',
}) => {
  store.addNotification({
    title: title,
    message: message,
    type: type,
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 2000,
      onScreen: true,
      pauseOnHover: true,
    },
    slidingExit: {
      duration: 500,
      timingFunction: 'ease-out',
      delay: 0,
    },
    touchSlidingExit: {
      swipe: {
        duration: 400,
        timingFunction: 'ease-out',
        delay: 0,
      },
      fade: {
        duration: 400,
        timingFunction: 'ease-out',
        delay: 0,
      },
    },
  });
};
