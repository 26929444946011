import React from 'react';
import editIcon from '../../assets/images/edit.svg';
import removeIcon from '../../assets/images/delete.svg';

import './dishItem.css';
import ImageHelper from '../ImageHelper';
import { useState } from 'react';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Typography } from '@material-ui/core';

const DishItem = ({ food, className, toogleActive, updateFood, active }) => {
  const [checkbox, setCheckbox] = useState(true);
  const [showDate, setShowDate] = useState(false);
  const [date, setDate] = useState('');

  const updateCheckBox = () => {
    if (!active) {
      setCheckbox(!checkbox);
      setShowDate(!checkbox);
    } else {
      updateFood(food, '');
    }
  };

  useEffect(() => {
    if (active) {
      setCheckbox(true);
    } else {
      setCheckbox(false);
    }
  }, []);
  const updateDate = () => {
    setShowDate(!checkbox);
    console.log(food);
    console.log(date);
    updateFood(food, date);
    // updateFood(food, date);
  };

  const showDateToUpdate = () => (
    <form onSubmit={updateDate} className='d-flex'>
      <input
        type='datetime-local'
        onChange={(e) => setDate(e.target.value)}
        required
      />
      <button type='submit'>Update</button>
    </form>
  );

  dayjs.extend(relativeTime);
  return (
    <div className={`dish-item d-flex flex-column width-80 ${className}`}>
      <ImageHelper product={food.image} className='dish-image' />
      <div className='dish-details width-100'>
        <h4>{food.name}</h4>
        <Typography paragraph className='dish-description'>
          {food.description}
        </Typography>
        <h4>Ingredients</h4>
        <Typography paragraph className='dish-description'>
          {food.ingredients}
        </Typography>

        <div className='d-flex justify-space-between align-center'>
          <h4>Change Active State</h4>
          <label className='switch'>
            <input
              type='checkbox'
              value={food.isActive ? true : false}
              onClick={updateCheckBox}
              defaultChecked={active}
            />
            <span className='slider round'></span>
          </label>
        </div>

        {active && (
          <div className='d-flex justify-space-between'>
            <div>Expires </div>
            {dayjs().to(dayjs(food.isActive))}
          </div>
        )}

        {showDate && showDateToUpdate()}

        <div className='bottom-dish-card d-flex justify-space-between align-center'>
          <h5>
            {food.price.currency.symbol}
            {food.price.amount}
          </h5>
          <div className='action-buttons'>
            <img src={editIcon} alt='' onClick={() => toogleActive(food)} />
            <img src={removeIcon} alt='' />
          </div>
        </div>
        <div className='card-date text-center text-disable'>
          {dayjs(food.createdAt).fromNow()}
        </div>
      </div>
    </div>
  );
};

export default DishItem;
