import React, { useState } from 'react';
import cook from '../assets/images/cok.png';
import redbg from '../assets/images/redbg.png';
import yellowbg from '../assets/images/yellowbg.png';

import axios from 'axios';
import { API } from '../backendUrl';
import { authenticate, isAuthenticated } from './helper/auth';
import { forgetEmailSend } from './helper/apihelper';
import { Redirect } from 'react-router-dom';
import UnAuthorized from '../hoc/UnAuthorized';
import { admin } from '../config/firebase';
import { notify } from '../helper/NotificationHelper';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  makeStyles,
  FormControl,
  TextField,
  CircularProgress,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
}));

const Login = () => {
  const [data, setData] = useState({
    email: '',
    password: '',
    loading: false,
    error: '',
    success: false,
    isRedirect: false,
  });
  const [forgetEmail, setForgetEmail] = useState('');
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [forgetLoading, setForgetLoading] = useState(false);
  const { email, password, loading, error, success, isRedirect } = data;
  const { user } = isAuthenticated();

  const signInWithFirebase = (data) => {
    admin
      .signInWithCustomToken(data.token)
      .then((res) => {
        admin.currentUser
          .getIdToken(true)
          .then(function (idToken) {
            data.token = idToken;
            authenticate(data, () => {
              setData({
                ...data,
                success: true,
              });
              notify({ message: 'Login Success!! ', type: 'success' });

              setTimeout(() => {
                setData({
                  ...data,
                  isRedirect: true,
                });
              }, 500);
            });
          })
          .catch(function (error) {
            setTimeout(() => {
              setData({
                ...data,
                loading: false,
              });
            }, 1000);

            notify({
              title: 'Oops!',
              message: 'Cannnot Genrate Token Try Again! ',
              type: 'danger',
            });
          });
      })
      .catch((err) => {
        notify({
          title: 'Oops!',
          message: 'Something went wrong. Try Again! ',
          type: 'danger',
        });
      });
  };

  const loginSubmit = (event) => {
    event.preventDefault();

    const dataObj = {
      email: email,
      password: password,
    };
    setData({
      ...data,
      loading: true,
    });
    axios
      .post(`${API}/signin`, dataObj)
      .then((data) => {
        signInWithFirebase(data.data);
      })
      .catch((err) => {
        if (err.response) {
          setData({
            ...data,
            error: err.response.data.error,
            loading: false,
          });

          setTimeout(
            () =>
              setData({
                ...data,
                error: false,
              }),
            3000
          );
        } else {
          setData({
            ...data,
            error: 'Server Not Working',
            loading: false,
          });

          setTimeout(
            () =>
              setData({
                ...data,
                error: false,
              }),
            3000
          );
        }
      });
  };

  const showError = () => <div className='show-error'>{error}</div>;
  const buildSuccess = () => <div className='show-success'>Login Success</div>;
  const buildLoading = () => <div className='show-loading'>Loading...</div>;

  const onChangeHandler = (name) => (event) => {
    setData({ ...data, [name]: event.target.value });
  };
  // const buildLoginForm = () => (
  //   <form onSubmit={loginSubmit}>
  //     <div className="form-group margin-vertical-20px">
  //       <div className="login-text d-flex">
  //         <img src={emailImg} alt="" />
  //         <div className="text">Email</div>
  //       </div>
  //       <input
  //         onChange={onChangeHandler('email')}
  //         type="email"
  //         name="email"
  //         placeholder="abc@meetnepali.com"
  //         value={email}
  //         required={true}
  //       />
  //     </div>
  //     <div className="form-group margin-vertical-20px">
  //       <div className="login-text d-flex">
  //         <img src={passwordImg} alt="" />
  //         <div className="text">Password</div>
  //       </div>
  //       <input
  //         onChange={onChangeHandler('password')}
  //         type="password"
  //         name="password"
  //         placeholder="Password "
  //         value={password}
  //         required={true}
  //       />
  //     </div>
  //     <div className="form-group margin-vertical-20px">
  //       {loading ? (
  //         <div className="d-flex align-center justify-center">
  //           <CircularProgress
  //             color="secondary"
  //             className={classes.marginTop}
  //             alignItems="center"
  //           />
  //         </div>
  //       ) : (
  //         <input
  //           type="submit"
  //           value="Login"
  //           className="form-button width-100"
  //         />
  //       )}
  //     </div>
  //   </form>
  // );
  const buildLoginForm = () => (
    <form class='login-form pt-5' onSubmit={loginSubmit}>
      <div class='form-block'>
        <label>
          <i class='fa fa-envelope pr-2'></i>
          <span>Email</span>
        </label>
        <br />
        <input
          onChange={onChangeHandler('email')}
          type='email'
          name='email'
          placeholder='abc@meetnepali.com'
          value={email}
          required={true}
        />
      </div>
      <div class='form-block'>
        <label>
          <i class='fa fa-lock pr-2'></i>
          <span>Password</span>
        </label>
        <br />
        <input
          onChange={onChangeHandler('password')}
          type='password'
          name='password'
          placeholder='Password '
          value={password}
          required={true}
        />
      </div>

      <div class='form-block'>
        {loading ? (
          <div className='d-flex align-center justify-center'>
            <CircularProgress
              color='secondary'
              className={classes.marginTop}
              alignItems='center'
            />
          </div>
        ) : (
          <button
            type='submit'
            class='btn btn-warning btn-sm text-white mt-2 px-4'
          >
            LOGIN
          </button>
        )}
      </div>
      <div className='form-block color-white cursor-p' onClick={handleClickOpen}>
        Forget Password?
      </div>
    </form>
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submitForgetPassword = (event) => {
    event.preventDefault();

    if (forgetEmail) {
      const data = {
        email: forgetEmail,
      };

      setForgetLoading(true);

      forgetEmailSend(data).then((res) => {
        console.log(res);
        if (res) {
          if (res.message == 'success') {
            notify({
              message: 'Success, Check email and change password!',
              type: 'success',
            });
            handleClose();
          } else {
            notify({
              title: 'Oops!',
              message: 'This email is not found in our databse!',
              type: 'danger',
            });
          }
        } else {
          notify({
            title: 'Oops!',
            message: 'Error While Sending Email.Try Again',
            type: 'danger',
          });
        }

        setForgetLoading(false);
      });
    } else {
      notify({
        title: 'Oops!',
        message: 'Please Enter valid Email! ',
        type: 'danger',
      });
    }
  };

  const performRedirect = () => {
    if (user && user.role === 1) {
      return <Redirect to='/cook/dashboard' />;
    } else if (user && user.role === 0) {
      return <Redirect to='/user/dashboard' />;
    }
  };
  return (
    <UnAuthorized>
      {/*<div className='login-page d-flex align-center justify-center'>
        <div className='login-image-container width-40 '>
          <img src={loginleftImg} alt='' />
        </div>
        <div className='login-box-container d-flex flex-column width-25 align-center margin-horizontal-10'>
          <div className='bigger-text'>Login</div>
        </div>
  </div>*/}
      <section class='login-box position-relative'>
        <div class='top-right-bg'>
          <img src={yellowbg} alt='' />
        </div>
        <div class='top-left-bg'>
          <img src={redbg} alt='' />
        </div>
        <div class='bottom-left-bg'>
          <img src={yellowbg} alt='' />
        </div>
        <div class='bottom-right-bg'>
          <img src={redbg} alt='' />
        </div>

        <div class='row'>
          <div class='col-md-6'>
            <h2 class='text-center pt-5'> Welcome to cook food</h2>
            <div class='login-image pt-5'>
              <img src={cook} alt='' />
            </div>
          </div>
          <div class='col-md-6'>
            <div class='image-box'>
              <h2 class='text-center text-white pt-5'>login</h2>
              {error && showError()}
              
              {buildLoginForm()}

              {isRedirect && performRedirect()}
            </div>
          </div>
        </div>
      </section>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='max-width-dialog-title'
      >
        <DialogTitle id='max-width-dialog-title'>Forget Password?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            We will send email to your emailaddress and you can change your
            password.
          </DialogContentText>
          <form className={classes.form} onSubmit={submitForgetPassword}>
            <FormControl className={classes.formControl}>
              <TextField
                autoFocus
                margin='dense'
                id='name'
                label='Email Address'
                type='email'
                value={forgetEmail}
                onChange={(e) => setForgetEmail(e.target.value)}
                fullWidth
                required
              />
            </FormControl>

            {forgetLoading ? (
              <div className='d-flex align-center justify-center'>
                <CircularProgress
                  color='secondary'
                  className={classes.marginTop}
                  alignItems='center'
                />
              </div>
            ) : (
              <Button
                type='submit'
                color='secondary'
                className={classes.marginTop}
              >
                Send
              </Button>
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </UnAuthorized>
  );
};

export default Login;
