import React from 'react';
import './style.css';

export default function MessageCard({ message, userId }) {
  const top = message.userId === userId ? 'flex-row-r' : '';
  const messageClass =
    message.userId === userId
      ? 'background-blue self-message '
      : 'background-grey friend-message';
  return (
    <div className={`${top} d-flex margin-all-5px`}>

      <div
        className={`message-card ${messageClass} padding-10-px border-radius-5px`}
      >
        {message.message}
      </div>
    </div>
  );
}
