import React from 'react';
import { Button } from '@material-ui/core';

export default function RatingButton({
  click,
  text = 'Rating and Feedback',
}) {
  return (
    <Button
      variant='contained'
      color='secondary'
      onClick={click}
    >
      {text}
    </Button>
  );
}
