import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from '../Pages/helper/auth';

const CustumerRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() && isAuthenticated().user.role === 1 ? (
          isAuthenticated().user.profileCompleted ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/completeProfile',
                state: { from: props.location },
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default CustumerRoute;
