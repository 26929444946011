import React, { useState } from 'react';
import FaceIcon from '@material-ui/icons/Face';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { TextField, IconButton, Avatar } from '@material-ui/core';
import { isAuthenticated } from '../Pages/helper/auth';
import { updateCartQuantity } from '../Pages/helper/apihelper';
import { notify } from '../helper/NotificationHelper';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ConfirmDialog from './ConfirmDialog';
import { API } from '../backendUrl';

export default function CartTableItem({ cart, deleteCartItem }) {
  const [updated, setUpdated] = useState(false);
  const [quantity, setQuantity] = useState(cart.quantity);
  const [error, setError] = useState('');
  const [total, setTotal] = useState(cart.total);
  const [openDialog, setOpenDialog] = useState(false);

  const toggleOpen = () => {
    setOpenDialog(!openDialog);
  };

  const handleChange = (event) => {
    setQuantity(event.target.value);
    if (parseInt(event.target.value) < 1) {
      setError('Quantity cannot be less than 1');
      return;
    } else {
      setError('');
    }
    if (cart.quantity === parseInt(event.target.value)) {
      console.log(true);
      setUpdated(false);
    } else {
      setUpdated(true);
    }
  };

  const updateQuantity = () => {
    const { user, token } = isAuthenticated();

    if (quantity < 1) {
      setError('Quantity cannot be less than 1');
      return;
    }
    const data = {
      quantity,
    };

    updateCartQuantity(data, user._id, cart._id, token).then((res) => {
      if (res) {
        cart = res;
        setUpdated(false);
        setTotal(res.total);
        notify({
          message: 'Cart Updated Sucessfully',
          type: 'success',
        });
      } else {
        notify({
          message: 'Cart Updated Failed!',
          type: 'danger',
        });
      }
    });
  };
  
  return (
    <div className='table-body d-flex'>
      <div className='td flex-2'>
        <div className='d-flex align-center justify-center'>
          <div className='image flex-1 d-flex justify-center'>
            <Avatar
              aria-label='recipe'
              alt={cart.food.name}
              src={cart.food.image && `${API}/uploads/${cart.food.image}`}
            />
          </div>
          <div className='flex-1'>
            <div className='name'>{cart.food.name}</div>
            <div className='d-flex align-center'>
              <FaceIcon />
              {cart.food.user.firstname} {cart.food.user.lastname}
            </div>
          </div>
        </div>
      </div>
      <div className='td flex-1 text-center'>
        <div className='d-flex'>
          <TextField
            type='number'
            id='standard-basic'
            label='Quantity'
            error={error}
            value={quantity}
            onChange={handleChange}
            helperText={error}
            name='quantity'
          />
          {updated && (
            <IconButton
              color='secondary'
              aria-label='add to shopping cart'
              onClick={updateQuantity}
            >
              <CheckCircleIcon />
            </IconButton>
          )}
        </div>
      </div>
      <div className='td flex-1 text-center'>
        {cart.food.price.currency.symbol}
        {cart.food.price.amount}
      </div>
      <div className='td flex-1 text-center'>
        {cart.food.price.currency.symbol}
        {total}
      </div>
      <div className='td flex-1 text-center'>
        <IconButton color='primary' onClick={toggleOpen}>
          <DeleteForeverIcon />
        </IconButton>
      </div>
      {openDialog && (
        <ConfirmDialog
          open={openDialog}
          description='Want to delete cart product?'
          title='Delete Cart item'
          handleClose={toggleOpen}
          okAction={() => deleteCartItem(cart._id)}
        />
      )}
    </div>
  );
}
