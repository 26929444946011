import React from 'react';
import './style.css';

export default function MessageImageCard({ message, userId }) {
  const top = message.userId === userId ? 'flex-row-r' : '';
  const messageClass =
    message.userId === userId
      ? 'self-message '
      : 'friend-message';
  return (
    <div className={`${top} d-flex margin-all-5px`}>
      <div
        className={`message-card ${messageClass}`}>
        <img src={message.message} alt="" className="message-image"/>
      </div>
    </div>
  );
}
