import React from 'react';
import Authorized from '../../hoc/Authorized';
import { useState } from 'react';
import ImageHelper from '../../Components/ImageHelper';
import { useEffect } from 'react';
import { isAuthenticated } from '../helper/auth';
import { useHistory } from 'react-router-dom';
import * as Status from '../../constant/OrderStatusConst';

import {
  AppBar,
  Tab,
  Badge,
  Typography,
  Button,
  Avatar,
} from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';

import {
  getCustumerOrderData,
  getCookOrderData,
  updateOrderStatus,
  giveRating,
} from '../helper/apihelper';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import OrderTableItem from '../../Components/OrderTableItem/OrderTableItem';
import { notify } from '../../helper/NotificationHelper';
import { changeOrderStatus } from '../helper/apihelper';
import Review from '../../Components/Review';
import RatingButton from '../../Components/RatingButton';
import ConfirmDialog from '../../Components/ConfirmDialog';
import { API } from '../../backendUrl';

const Order = () => {
  const [savesOrders, setSavesOrders] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const { user, token } = isAuthenticated();
  const [hide, setHide] = useState([]);
  let history = useHistory();
  const [value, setValue] = React.useState('1');
  const [orderRequestCount, setOrderRequestCount] = useState(0);
  const [activeFoods, setActiveFoods] = useState([]);
  const [activeOrder, setActiveOrder] = useState('');
  const [activeUser, setActiveUser] = useState('');
  const [rateFoods, setRateFoods] = useState(false);
  const [reviewOpen, setReviewOpen] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [description, setDescription] = useState('');
  const [activeStatus, setactiveStatus] = useState({
    activeBuyerStatus: '',
    activeSellerStatus: '',
  });

  const [gotoTab, setGotoTab] = useState(1);

  const { activeBuyerStatus, activeSellerStatus } = activeStatus;

  const handleReviewClickOpen = (foods, order, status) => {
    console.log(order);
    setActiveUser(user.role === 0 ? order.seller._id : order.buyer._id);
    setActiveFoods(foods);
    setActiveOrder(order._id);
    setReviewOpen(true);
    setRateFoods(status === Status.COMPLETE);
  };

  const handleReviewClose = () => {
    setReviewOpen(false);
  };
  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const changeOrderItem = (valueNum) => {
    let value = 'Request';
    if (valueNum === '2') {
      value = 'Active';
    } else if (valueNum === '3') {
      value = 'Complete';
    } else if (valueNum === '4') {
      value = 'Cancel';
    }
    let newOrderItems;
    if (value === Status.ACTIVE) {
      newOrderItems = savesOrders.filter((o) =>
        user.role === 0
          ? o.status.buyer === Status.ACTIVE
          : o.status.seller === Status.ACTIVE ||
            o.status.seller === Status.READY_FOR_DELIVERY ||
            (o.status.seller === Status.COMPLETE &&
              o.status.buyer === Status.ACTIVE)
      );
    } else if (value === Status.COMPLETE) {
      newOrderItems = savesOrders.filter(
        (o) =>
          o.status.buyer === Status.COMPLETE &&
          o.status.seller === Status.COMPLETE
      );
    } else if (value === Status.CANCEL) {
      newOrderItems = savesOrders.filter(
        (o) =>
          o.status.buyer === Status.CANCEL && o.status.seller === Status.CANCEL
      );
    } else if (value === Status.REQUEST) {
      newOrderItems = savesOrders.filter((o) =>
        user.role === 0
          ? o.status.buyer === Status.REQUEST
          : o.status.seller === Status.REQUEST
      );
    }
    setOrderItems(newOrderItems);
  };

  const finalStatusChange = (orderId, buyerStatus, sellerStatus) => {
    const data = {
      buyerStatus,
      sellerStatus,
    };
    handleCloseConfirmDialog();
    console.log(orderId, buyerStatus, sellerStatus);
    changeOrderStatus(user._id, token, orderId, data).then((res) => {
      if (buyerStatus === Status.ACTIVE && sellerStatus === Status.ACTIVE) {
        setOrderRequestCount(orderRequestCount - 1);
      }

      if (
        (buyerStatus === Status.ACTIVE && sellerStatus === Status.ACTIVE) ||
        (buyerStatus === Status.CANCEL && sellerStatus === Status.CANCEL) ||
        (buyerStatus === Status.COMPLETE && sellerStatus === Status.COMPLETE)
      ) {
        const neworder = orderItems.filter((o) => o._id !== orderId);
        setOrderItems(neworder);
      } else if (
        (buyerStatus === Status.ACTIVE &&
          sellerStatus === Status.READY_FOR_DELIVERY) ||
        (buyerStatus === Status.ACTIVE && sellerStatus === Status.COMPLETE)
      ) {
        const index = orderItems.findIndex((o) => o._id === orderId);
        if (index !== -1) {
          orderItems[index].status = {
            buyer: buyerStatus,
            seller: sellerStatus,
          };
          const newOrder = orderItems;
          setOrderItems(newOrder);
        }
      }
      const sindex = savesOrders.findIndex((o) => o._id === orderId);
      savesOrders[sindex].status = {
        buyer: buyerStatus,
        seller: sellerStatus,
      };
      const newSaveOrders = savesOrders;
      setSavesOrders(newSaveOrders);

      gotoTab !== 0 && setValue(gotoTab);
      gotoTab !== 0 && changeOrderItem(gotoTab);
    });
  };

  const statusChange = (orderId, buyerStatus, sellerStatus) => {
    setActiveOrder(orderId);
    setactiveStatus({
      ...activeStatus,
      activeBuyerStatus: buyerStatus,
      activeSellerStatus: sellerStatus,
    });

    if (buyerStatus === Status.CANCEL && sellerStatus === Status.CANCEL) {
      setDescription('Are you sure want to cancel the order??');
      setGotoTab('4');
    } else if (
      buyerStatus === Status.ACTIVE &&
      sellerStatus === Status.ACTIVE
    ) {
      setGotoTab('2');
      setDescription('Are you sure want to accept this order??');
    } else if (
      buyerStatus === Status.ACTIVE &&
      sellerStatus === Status.READY_FOR_DELIVERY
    ) {
      setGotoTab(0);
      setDescription('Are you sure your food is ready for delivery??');
    } else if (
      buyerStatus === Status.COMPLETE &&
      sellerStatus === Status.COMPLETE
    ) {
      setGotoTab('3');
      setDescription('Are you sure want to complete this order??');
    } else if (
      buyerStatus === Status.ACTIVE &&
      sellerStatus === Status.COMPLETE
    ) {
      setGotoTab(0);
      setDescription('Are you sure want to complete this order??');
    }
    setOpenConfirmDialog(true);
  };

  useEffect(() => {
    const { user, token } = isAuthenticated();
    user.role === 0
      ? getCustumerOrderData(user._id, token).then((data) => {
          console.log(data);
          data.forEach((d) => {
            if (d.status.buyer === Status.REQUEST) {
              setOrderRequestCount(orderRequestCount + 1);
            }
            hide.push(true);
          });
          setOrderItems(
            data.filter((order) => order.status.buyer === Status.REQUEST)
          );
          setSavesOrders(data);
        })
      : getCookOrderData(user._id, token).then((data) => {
          console.log(data);
          data.forEach((d) => {
            if (d.status.seller === Status.REQUEST) {
              setOrderRequestCount(orderRequestCount + 1);
            }
            hide.push(true);
          });
          setOrderItems(
            data.filter((order) => order.status.seller === Status.REQUEST)
          );
          setSavesOrders(data);
        });
  }, []);

  const goToMessage = () => {
    if (user.role === 0) {
      history.push('/user/messages');
    } else {
      history.push('/cook/messages');
    }
  };
  const submitReview = (data) => {
    giveRating(data, user._id, activeOrder, token).then((res) => {
      if (res) {
        notify({
          type: 'success',
          message: 'Giving review sucessfull',
        });
        const index = orderItems.findIndex((o) => o._id === activeOrder);
        if (user.role === 0) {
          orderItems[index].review.buyer = true;
        } else {
          orderItems[index].review.seller = true;
        }
        const newOrderItems = orderItems;
        setOrderItems(newOrderItems);
        const i = savesOrders.findIndex((o) => o._id === activeOrder);
        if (user.role === 0) {
          savesOrders[i].review.buyer = true;
        } else {
          savesOrders[i].review.seller = true;
        }
        const newSavedOrderItems = orderItems;
        setSavesOrders(newSavedOrderItems);
      } else {
        notify({
          type: 'danger',
          message: 'Cannot Give Review. Try Again!',
        });
      }
    });
  };

  const toogleHide = (index) => {
    let newArr = [...hide];
    newArr[index] = !hide[index];
    setHide(newArr);
  };

  const buildTable = () => (
    <div className='table'>
      {orderItems.length > 0 ? (
        orderItems.map((order, index) => (
          <div>
            <div
              className='d-flex justify-space-between background-grey padding-10-px margin-all-5px'
              onClick={() => toogleHide(index)}
            >
              <div className='order-details'>
                {order.seller && (
                  <Typography>
                    {user.role === 0
                      ? `Cook: ${order.seller.firstname} ${order.seller.lastname}`
                      : `Buyer: ${order.buyer.firstname} ${order.buyer.lastname}`}
                  </Typography>
                )}
                <Typography className='small-text' variant='subtitle2'>
                  #Order Id: {order._id}
                </Typography>
                <div className={`food-items ${hide[index] ? '' : 'hide'}`}>
                  {order.foods.map((food) => (
                    <Avatar
                      aria-label='recipe'
                      style={{ display: 'inline-block' }}
                      alt={food.food.name}
                      src={
                        food.food.image && `${API}/uploads/${food.food.image}`
                      }
                    />
                  ))}
                </div>
                <div className='order-time'>
                  {dayjs(order.createdAt).fromNow()}
                </div>
              </div>
              <div className='d-flex align-center justify-center'>
                {user.role === 1
                  ? order.status.seller === 'Request' && (
                      <div>
                        <Button
                          variant='contained'
                          onClick={() =>
                            statusChange(
                              order._id,
                              Status.CANCEL,
                              Status.CANCEL
                            )
                          }
                        >
                          Reject
                        </Button>
                        <Button
                          variant='contained'
                          color='primary'
                          onClick={() =>
                            statusChange(
                              order._id,
                              Status.ACTIVE,
                              Status.ACTIVE
                            )
                          }
                        >
                          Accept
                        </Button>
                      </div>
                    )
                  : order.status.buyer === 'Request' && (
                      <Button
                        variant='contained'
                        onClick={() =>
                          statusChange(order._id, Status.CANCEL, Status.CANCEL)
                        }
                      >
                        Cancel
                      </Button>
                    )}

                {order.status.seller === Status.ACTIVE &&
                  order.status.buyer === Status.ACTIVE &&
                  user.role === 1 && (
                    <div>
                      <Button
                        variant='contained'
                        onClick={() =>
                          statusChange(order._id, Status.ACTIVE, Status.CANCEL)
                        }
                      >
                        Cancel Order
                      </Button>

                      <Button
                        variant='contained'
                        color='primary'
                        onClick={() =>
                          statusChange(
                            order._id,
                            Status.ACTIVE,
                            Status.READY_FOR_DELIVERY
                          )
                        }
                      >
                        Ready For Delivery
                      </Button>
                    </div>
                  )}

                {order.status.seller === Status.READY_FOR_DELIVERY &&
                  user.role === 1 && (
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() =>
                        statusChange(order._id, Status.ACTIVE, Status.COMPLETE)
                      }
                    >
                      Complete Order
                    </Button>
                  )}

                {order.status.seller === Status.READY_FOR_DELIVERY &&
                  user.role === 0 && (
                    <Button variant='contained' color='secondary' disabled>
                      Order is Ready for Delivery
                    </Button>
                  )}

                {order.status.seller === Status.COMPLETE &&
                  order.status.buyer === Status.ACTIVE &&
                  (user.role === 0 ? (
                    <div>
                      <Button
                        variant='contained'
                        onClick={() =>
                          statusChange(order._id, Status.ACTIVE, Status.ACTIVE)
                        }
                      >
                        Reject
                      </Button>
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={() =>
                          statusChange(
                            order._id,
                            Status.COMPLETE,
                            Status.COMPLETE
                          )
                        }
                      >
                        Accept Order
                      </Button>
                    </div>
                  ) : (
                    <Button variant='contained' disabled>
                      Complete Request Sent
                    </Button>
                  ))}

                {order.status.seller === Status.COMPLETE &&
                  order.status.buyer === Status.COMPLETE &&
                  user.role === 1 &&
                  (!order.review.seller ? (
                    <RatingButton
                      text='Rate a buyer'
                      click={() =>
                        handleReviewClickOpen(
                          order.foods,
                          order,
                          Status.COMPLETE
                        )
                      }
                    />
                  ) : (
                    <Button variant='contained' disabled>
                      Reviewed
                    </Button>
                  ))}

                {order.status.seller === Status.COMPLETE &&
                  order.status.buyer === Status.COMPLETE &&
                  user.role === 0 &&
                  (!order.review.buyer ? (
                    <RatingButton
                      text='Rate Cook & Foods'
                      click={() =>
                        handleReviewClickOpen(
                          order.foods,
                          order,
                          Status.COMPLETE
                        )
                      }
                    />
                  ) : (
                    <Button variant='contained' disabled>
                      Reviewed
                    </Button>
                  ))}

                {order.status.seller === Status.CANCEL &&
                  order.status.buyer === Status.CANCEL &&
                  user.role === 0 &&
                  (!order.review.buyer ? (
                    <RatingButton
                      text='Rate a Seller'
                      click={() =>
                        handleReviewClickOpen(order.foods, order, Status.CANCEL)
                      }
                    />
                  ) : (
                    <Button variant='contained' color='secondary' disabled>
                      Reviewed
                    </Button>
                  ))}
                {order.status.seller === Status.CANCEL &&
                  order.status.buyer === Status.CANCEL &&
                  user.role === 1 &&
                  (!order.review.seller ? (
                    <RatingButton
                      text='Rate a Buyer'
                      click={() =>
                        handleReviewClickOpen(order.foods, order, Status.CANCEL)
                      }
                    />
                  ) : (
                    <Button variant='contained' color='secondary' disabled>
                      Reviewed
                    </Button>
                  ))}
                <i
                  className={`fa fa-caret-down ${
                    hide[index] ? '' : 'rotate-180'
                  } fa-lg margin-all-5px`}
                  aria-hidden='true'
                ></i>
              </div>
            </div>
            <div className={`table-group ${hide[index] ? 'hide' : ''}`}>
              {order.foods.map((food) => (
                <OrderTableItem food={food} />
              ))}
            </div>
          </div>
        ))
      ) : (
        <div>No order found of this category</div>
      )}
    </div>
  );

  const changeFoodStatus = (food, status, orderId) => {
    console.log(food);
    console.log(status);
    console.log(orderId);
    const updateOrder = {
      status: status,
    };

    updateOrderStatus(user._id, food._id, orderId, token, updateOrder).then(
      (data) => {
        notify({
          message: 'Updated Order Status Sucessfully',
          type: 'success',
        });
        console.log(data);
      }
    );
  };
  dayjs.extend(relativeTime);
  return (
    <Authorized>
      <div className='content padding-all-50px'>
        <h1>Welcome to order page</h1>

        <div className='order-details '>
          <div className='text background-primary padding-10-px text-center'>
            {user.role === 0 ? 'Foods you have ordered' : 'Oders you have get'}
          </div>
          <TabContext value={value}>
            <AppBar position='static' color='default'>
              <TabList onChange={handleChange} aria-label='simple tabs example'>
                <Tab
                  label={
                    <Badge badgeContent={orderRequestCount} color='error'>
                      {user.role === 0 ? 'Order Placed' : 'Order Received'}
                    </Badge>
                  }
                  value='1'
                  onClick={() => changeOrderItem('1')}
                />
                <Tab
                  label='Accepted Order'
                  value='2'
                  onClick={() => changeOrderItem('2')}
                />
                <Tab
                  label='Complete Order'
                  value='3'
                  onClick={() => changeOrderItem('3')}
                />
                <Tab
                  label='Canceled Order'
                  value='4'
                  onClick={() => changeOrderItem('4')}
                />
              </TabList>
            </AppBar>
            <TabPanel value='1'>{buildTable()}</TabPanel>
            <TabPanel value='2'>{buildTable()}</TabPanel>
            <TabPanel value='3'>{buildTable()}</TabPanel>
            <TabPanel value='4'>{buildTable()}</TabPanel>
          </TabContext>
        </div>

        {reviewOpen &&
          (user.role === 0 ? (
            <Review
              open={reviewOpen}
              handleClose={handleReviewClose}
              foods={activeFoods}
              order={activeOrder}
              userId={activeUser}
              giveRating={submitReview}
              rateFoods={rateFoods}
            />
          ) : (
            <Review
              open={reviewOpen}
              handleClose={handleReviewClose}
              order={activeOrder}
              userId={activeUser}
              giveRating={submitReview}
              rateFoods={rateFoods}
            />
          ))}
        {openConfirmDialog && (
          <ConfirmDialog
            open={openConfirmDialog}
            handleClose={handleCloseConfirmDialog}
            title='Change Order Status'
            description={description}
            okAction={() =>
              finalStatusChange(
                activeOrder,
                activeBuyerStatus,
                activeSellerStatus
              )
            }
          />
        )}
      </div>
    </Authorized>
  );
};
export default Order;
