import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import React from 'react';

export default function ConfirmDialog({
  title,
  description,
  okAction,
  open,
  handleClose,
}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='default'>
          Cancel
        </Button>
        <Button onClick={okAction} color='secondary' autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
