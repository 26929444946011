import axios from 'axios';
import { API } from '../../backendUrl';
import { signout } from './auth';

const signOutUser = (err) => {
  if (err.response.status === 401) {
    signout(() => {
      window.location.href = '/login';
    });
  }
};

export const getFoodItems = (userId, token) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  return axios
    .get(`${API}/products/${userId}`)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      if (err.response) {
        console.error(err);
        signOutUser(err);
      }
    });
};

export const searchFoods = (keyword, limit, page) => {
  return axios
    .get(`${API}/products/search/${keyword}?limit=${limit}&page=${page}`)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      if (err.response) {
        console.error(err);
        signOutUser(err);
      }
    });
};

export const updateIsActive = (data, foodId, userId, token) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  return axios
    .put(`${API}/updateIsActive/${foodId}/${userId}`, data)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      if (err.response) {
        console.error(err);
        signOutUser(err);
      }
    });
};

export const addFavourite = (foodId, userId, token) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  return axios
    .put(`${API}/product/addFavourite/${foodId}/${userId}`)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      if (err.response) {
        console.error(err);
        signOutUser(err);
      }
    });
};

export const removeFavourite = (foodId, userId, token) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  return axios
    .put(`${API}/product/removeFavourite/${foodId}/${userId}`)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      if (err.response) {
        console.error(err);
        signOutUser(err);
      }
    });
};

export const addFoodToCart = (data, foodId, userId, token) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  return axios
    .post(`${API}/cart/${foodId}/${userId}`, data)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      if (err.response) {
        console.error(err);
        signOutUser(err);
      }
    });
};

export const getCartItem = (userId, token) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  return axios
    .get(`${API}/cart/${userId}`)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      if (err.response) {
        console.error(err);
        signOutUser(err);
      }
    });
};

export const deleteCartItem = (cartId, userId, token) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  return axios
    .delete(`${API}/cart/${cartId}/${userId}`)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      if (err.response) {
        console.error(err);
        signOutUser(err);
      }
    });
};

export const getAddress = () => {
  return axios
    .get(`${API}/user/getAddress`)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      if (err.response) {
        console.error(err);
        signOutUser(err);
      }
    });
};

export const getUserInfo = (userId, token) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  return axios
    .get(`${API}/user/${userId}`)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      if (err.response) {
        console.error(err);
        signOutUser(err);
      }
    });
};

export const updateUserInfo = (userId, token, data) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  return axios
    .put(`${API}/user/${userId}`, data)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      if (err.response) {
        console.error(err);
        signOutUser(err);
      }
    });
};

export const createOrder = (userId, token, data) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  return axios
    .post(`${API}/order/create/${userId}`, data)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      if (err.response) {
        console.error(err);
        signOutUser(err);
      }
    });
};

export const getCustumerOrderData = (userId, token) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  return axios
    .get(`${API}/order/custumer/${userId}`)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      if (err.response) {
        console.error(err);
        signOutUser(err);
      }
    });
};

export const getCookOrderData = (userId, token) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  return axios
    .get(`${API}/order/cook/${userId}`)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      if (err.response) {
        console.error(err);
        signOutUser(err);
      }
    });
};

export const getChatUserList = (userId, token) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  return axios
    .get(`${API}/chat/${userId}`)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      if (err.response) {
        console.error(err);
        signOutUser(err);
      }
    });
};

export const updateOrderStatus = (userId, foodId, orderId, token, data) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

  console.log(foodId);
  return axios
    .put(`${API}/order/${orderId}/${foodId}/${userId}`, data)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      if (err.response) {
        console.error(err);
        signOutUser(err);
      }
    });
};

export const getOrderById = (userId, orderId, token) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  return axios
    .get(`${API}/order/getOne/${orderId}/${userId}`)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      if (err.response) {
        console.error(err);
        signOutUser(err);
      }
    });
};

export const getFavouriteFoods = (userId, token) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  return axios
    .get(`${API}/user/favourite/${userId}`)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      if (err.response) {
        console.error(err);
        signOutUser(err);
      }
    });
};

export const getUserDetails = (userId, token) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  return axios
    .get(`${API}/user/${userId}`)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      if (err.response) {
        console.error(err);
        signOutUser(err);
      }
    });
};

export const changeOrderStatus = (userId, token, orderId, data) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  return axios
    .put(`${API}/order/${orderId}/${userId}`, data)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      if (err.response) {
        console.error(err);
        signOutUser(err);
      }
    });
};

export const forgetEmailSend = (data) => {
  return axios
    .post(`${API}/forgetPassword`, data)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      if (err.response) {
        console.error(err);
        // signOutUser(err);
      }
      return null;
    });
};

export const changePassword = (data) => {
  return axios
    .post(`${API}/changePassword`, data)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      if (err.response) {
        console.error(err);
        signOutUser(err);
      }
      return null;
    });
};

export const getCountries = () => {
  return axios
    .get(`${API}/countries`)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      if (err.response) {
        console.error(err);
        signOutUser(err);
      }
      return null;
    });
};
export const getStateFromCountry = (data) => {
  return axios
    .post(`${API}/states`, data)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      if (err.response) {
        console.error(err);
        signOutUser(err);
      }
      return null;
    });
};
export const getCityFromState = (data) => {
  return axios
    .post(`${API}/cities`, data)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      if (err.response) {
        console.error(err);
        signOutUser(err);
      }
      return null;
    });
};

export const changePasswordSalt = (data) => {
  return axios
    .put(`${API}/forgetPassword`, data)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      if (err.response) {
        console.error(err);
        signOutUser(err);
      }
      return null;
    });
};

export const updateCartQuantity = (data, userId, cartId, token) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  return axios
    .put(`${API}/cart/${cartId}/${userId}`, data)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      if (err.response) {
        console.error(err);
        signOutUser(err);
      }
      return null;
    });
};

export const giveRating = (data, userId, orderId, token) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  return axios
    .post(`${API}/rating/${orderId}/${userId}`, data)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      if (err.response) {
        console.error(err);
        signOutUser(err);
      }
      return null;
    });
};
export const getUserRatings = (userId, token) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  return axios
    .get(`${API}/ratings/${userId}`)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      if (err.response) {
        console.error(err);
        signOutUser(err);
      }
      return null;
    });
};

export const uploadProfileImage = (userId, token, bodyFormData) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

  return axios
    .put(`${API}/uploadImage/${userId}`, bodyFormData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      if (err.response) {
        console.error(err);
        signOutUser(err);
      }
      return null;
    });
};
