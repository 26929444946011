import React from 'react';
import { useEffect } from 'react';
import { getOrderById, updateOrderStatus } from '../../Pages/helper/apihelper';
import { isAuthenticated } from '../../Pages/helper/auth';
import { useState } from 'react';
import ImageHelper from '../ImageHelper';
import { notify } from '../../helper/NotificationHelper';

const ChangeOrderStatusDialog = ({ close, orderId }) => {
  const [foods, setFoods] = useState([]);
  const { user, token } = isAuthenticated();

  useEffect(() => {
    getOrderById(user._id, orderId, token).then((res) => {
      setFoods(res.foods);
    });
  }, []);

  const changeOrderStatus = (food, status) => {
    const updateOrder = {
      status: status,
    };

    updateOrderStatus(user._id, food._id, orderId, token, updateOrder).then(
      (data) => {
        notify({ message: 'Order Changed Sucess', type: 'success' });
      }
    );
  };
  return (
    <div className="dialog">
      <div className="backdrop"></div>
      <div className="dialog-box background-grey">
        <div className="width-100 d-flex justify-end">
          <div className="close" onClick={close}>
            Close
          </div>
        </div>
        <div className="dialog-content ">
          <h1>Want to Change the Order Status?</h1>
          {foods.map((food) => (
            <div className={`table-body d-flex `}>
              <div className="td flex-1">
                <div className="d-flex align-center justify-space-around">
                  <ImageHelper
                    product={food.food}
                    className="radius-circle hw-50px margin-all-5px"
                  />
                </div>
              </div>
              <div className="td flex-1 text-center">
                <select
                  name="status"
                  id="status"
                  onChange={(e) => changeOrderStatus(food.food, e.target.value)}
                >
                  <option
                    value="Processing"
                    selected={food.status === 'Processing'}
                  >
                    Processing
                  </option>
                  <option value="Cancel" selected={food.status === 'Cancel'}>
                    Cancel
                  </option>
                  <option
                    value="Complete"
                    selected={food.status === 'Complete'}
                  >
                    Complete
                  </option>
                </select>
              </div>
              {
                //   <div className="td flex-1 text-center">
                //   <button onClick={() => changeOrderStatus(food.food)}>
                //     Change Status
                //   </button>
                // </div>
              }
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChangeOrderStatusDialog;
